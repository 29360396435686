/*
Research:
- Prevent jitters in svg animation: https://css-tricks.com/animating-svg-css/
- https://stackoverflow.com/questions/6312993/javascript-seconds-to-time-string-with-format-hhmmss
*/

const makeTimer = (seconds) => {
  console.log("seconds:", seconds);
  var date = new Date(seconds * 1000); // multiply by 1000 because Date() requires miliseconds
  var timeStr = date.toTimeString().split(' ')[0];
  var timeStr2 = timeStr.split(':').slice(1);

  console.log("timeStr2:", timeStr2);

  return timeStr2.join(':');
}

const DigitalClock = ({
  height,
  width,
  color,
  timer,
  digitalClockStyles = {},
  transitionDelay = 1,
  durationInMs,
}) => {
  // if (!timer) return <></>;

  const durationInS = durationInMs / 1000;

  return (
    <div
      key={"digital-clock-"+durationInS}
      className={`digital-clock timer-${timer} inverse-timer-${durationInS - timer}`}
      style={{
        ...{
          transition: `all ${transitionDelay}s`,
        },
        ...digitalClockStyles,
      }}
    >
      {makeTimer(durationInS-timer).substring(1)}
    </div>
  );
};

export default DigitalClock;
