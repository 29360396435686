import React, { useEffect, useState } from "react";
import Game from "common/components/GameRenderer";
import GameScene from "./src/scene";
import GamesMenu from "common/components/GamesMenu";
import Multiplayer from "common/modules/multiplayer";
import BackgroundGame from "common/components/BackgroundGame";
import TouchJoystick from "common/modules/controllers/touch";
import InGameLeaderboard from "common/components/InGameLeaderboard";
import { usePlayersList } from "common/hooks/multiplayer";

// import "./style.css";

export default function GamePage({ gameInfo }) {
  const multiplayer = Multiplayer();
  const players = usePlayersList(true);
  const [winnerFound, setWinnerFound] = useState(
    multiplayer.getState("winner") || false
  );

  useEffect(() => {
    var controller = null;
    var cleanupFns = [];
    cleanupFns.push(
      multiplayer.on("state", (state, key) => {
        setWinnerFound(state["winner"] || false);
      })
    );

    if (!multiplayer.isSpectator) {
      controller = new TouchJoystick({
        degree: true,
        className: "icetruck",
        labels: { dpad: "STEER", b1: "FIRE" },
      });
      multiplayer.attachController(controller);
    }

    return () => {
      cleanupFns.forEach((cleanup) => cleanup());
      if (!multiplayer.isSpectator) {
        multiplayer.detachControllerLegacy();
        if (controller) controller.destroy();
        controller = null;
      }
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <BackgroundGame style={{ backgroundColor: "#57B233" }}></BackgroundGame>
      <GamesMenu
        key="gamesmenu"
        inGame
        showLogo
        gameInfo={gameInfo}
        showRestart={winnerFound && multiplayer.isHost}
      />
      {multiplayer.isSpectator ? (
        <InGameLeaderboard
          playerStatesAndScores={players.map((playerState) => {
            const laps = playerState.getState("laps") || 0;
            return {
              state: playerState,
              score: (
                <>
                  {laps}
                  <sub style={{ fontSize: "2rem" }}>/5</sub>
                </>
              ),
            };
          })}
        />
      ) : (
        false
      )}
      {multiplayer.isSpectator ? (
        <Game
          key="game"
          gameInfo={gameInfo}
          scenes={[GameScene]}
          backgroundColor="#57B233"
          physics={{
            default: "matter",
            matter: {
              gravity: {
                y: 0,
              },
              debug: false,
            },
          }}
        />
      ) : (
        false
      )}
    </>
  );
}
