import { useEffect } from "react";
import Multiplayer from "../../modules/multiplayer";
import { useGlobalState } from "../../hooks/multiplayer";
import { useState } from "react";

class RoundAPI {
  constructor(gameConfig, currentRoundsUniqueID) {
    this.multiplayer = Multiplayer();
    this.gameConfig = gameConfig;
    this.currentRoundsUniqueID = currentRoundsUniqueID;
    this._callMain();
  }

  get currentRound() {
    return this.multiplayer.getState("_round") || 0;
  }

  _callMain() {
    if (
      this.multiplayer.singlePlayerMode ||
      this.multiplayer.isRenderServerOrHostIfNotCasting
    ) {
      this.gameConfig?.main({ multiplayer: this.multiplayer, rounds: this });
    }
  }
  endRound() {
    if (
      this.multiplayer.getState("_roundsUniqueID") !==
      this.currentRoundsUniqueID
    ) {
      console.log("roundsUniqueID mismatch, not ending round");
      return;
    }
    this.multiplayer.resetRoundState();
    this.multiplayer.setState("_round", this.currentRound + 1);
    this._callMain();
    // this.multiplayer.connection.once("sync", () => {
    //   // wait for state to propogate

    // });
  }
}

export default function RoundsWrapper({
  totalRounds,
  RoundComponent,
  gameConfig,
  editorConfig,
  resetTriviaGameConfig
}) {
  const multiplayer = Multiplayer();
  const currentRound = useGlobalState("_round");
  const [currentRoundsUniqueID, setCurrentRoundsUniqueID] = useState(
    Math.random()
  );
  useEffect(() => {
    multiplayer.setState("_round", 0);
    multiplayer.setState("_totalRounds", totalRounds || 1);
    multiplayer.setState("_roundsUniqueID", currentRoundsUniqueID);
    new RoundAPI(gameConfig, currentRoundsUniqueID); // also calls the main function
  }, []);

  return (
    <div key={currentRound}>
      <RoundComponent
        totalRounds={totalRounds}
        currentRound={currentRound}
        isLastRound={currentRound === totalRounds - 1}
        endRound={() => {
          if (
            multiplayer.getState("_roundsUniqueID") !== currentRoundsUniqueID
          ) {
            console.log("roundsUniqueID mismatch, not ending round");
            return;
          }
          console.log("ending round", currentRound);
          multiplayer.resetRoundState();
          multiplayer.setState("_round", currentRound + 1);
        }}
        editorConfig={editorConfig}
        resetTriviaGameConfig={resetTriviaGameConfig}
      />
    </div>
  );
}
