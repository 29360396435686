import React from "react";
import {
  useOtherPlayerState,
  usePlayersList,
  usePlayerState,
  useGlobalState,
  useGlobalRoundState,
} from "../../hooks/multiplayer";
import AvatarIcon from "../AvatarIcon";
import PropTypes from "prop-types";
import "./style.css";
import { useEffect, useState, useRef } from "react";
import PlayersAutofitHorizontal from "../PlayersAutofitHorizontal";
import Element from "../Element";

import tickImg from "../../imgs/tick.png";
import FlyingReactions from "../FlyingReactions";

export function AvatarIconOnly({
  centered,
  bottom,
  containerClassNames,
  showNames,
  showScores,
  showHost = true,
  mobileMode,
  initialScore = 0,
  playersList = [],
  noAbsolute,
  numberOfEmptySlots = 0,
  numberOfAvailableSlots = 0,
  stripStyles = {},
  scrollable = true,
  alignTo = "auto",
  position = "top",
  showUserAnswers: _showUserAnswers,
  showUserScoreDiff: _showUserScoreDiff,
  playerDefaultImage,
  showTick = true,
  avatarParentContainerClassName = "",
}) {
  const _players = usePlayersList(true);

  const showUserAnswers = useGlobalRoundState(
    "showUserAnswers",
    _showUserAnswers
  );
  const showUserScoreDiff = useGlobalRoundState(
    "showUserScoreDiff",
    _showUserScoreDiff
  );

  const numberOfRemainingSlots =
    numberOfAvailableSlots -
    (playersList.length ? playersList : _players).length;
  const players = (playersList.length ? playersList : _players).concat([
    ...Array(
      numberOfEmptySlots ||
        (numberOfRemainingSlots > 0 ? numberOfRemainingSlots : 0)
    ),
  ]);
  console.log("[AvatarIconOnly] numberOfEmptySlots", numberOfEmptySlots);
  console.log("[AvatarIconOnly] scrollable", scrollable);
  console.log("[AvatarIconOnly] players", players);

  // const playerAvatarStripRef = useRef();
  const playerAvatarRef = useRef();
  const playerAvatarStripWidth =
    playerAvatarRef.current?.offsetWidth * players?.length || 0;
  const [isOverflow, setIsOverflow] = useState(
    alignTo === "left" ? true : false
  );

  // console.log("pl", players);

  useEffect(() => {
    if (alignTo !== "auto") return;

    console.log(
      "playerAvatarStripWidth, window.clientWidth",
      playerAvatarStripWidth,
      window.innerWidth
    );
    if (playerAvatarStripWidth >= window.innerWidth) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
    }
  }, [playerAvatarStripWidth]);

  if (scrollable) {
    return (
      <div
        className={`player-avatar-parent ${position} ${avatarParentContainerClassName}`}
      >
        <div
          // ref={playerAvatarStripRef}
          className={
            "player-avatar-strip" +
            (mobileMode ? " mobile" : " cast") +
            (noAbsolute ? " noabsolute" : "") +
            (centered ? " centered" : "") +
            (containerClassNames ? " " + containerClassNames : "") +
            " " +
            position
          }
          id="scrollbar1"
          style={{
            ...{
              overflowX: "auto",
              overflowY: "visible",
              paddingBottom: "3rem",
              paddingTop: position === "bottom" ? "16rem" : undefined,
              justifyContent: isOverflow ? "flex-start" : "center",
              // height: "10rem",
            },
            ...stripStyles,
          }}
        >
          {players.map((playerState, idx) => {
            console.log("playerstate:", playerState);
            if (playerState) {
              const playerScore =
                initialScore +
                (playerState.getState("profile")?.points ||
                  playerState.getState("profile")?.score ||
                  0);
              const playerPhoto = playerState.getState("profile")?.photo;
              // const rotationValues = [-8, 6, -4, 9];
              const rotationValues = [0, 0, 0, 0];
              return (
                <AvatarIconOnlyItem
                  key={playerState.id}
                  containerRef={idx === 0 ? playerAvatarRef : undefined}
                  playerContainerStyles={{
                    animation: `0.5s bounce-in ${
                      0.1 + idx * 0.25
                    }s ease-in-out forwards`,
                    // margin: "auto",
                    // marginLeft: idx === 0 ? "auto" : undefined,
                    // marginRight: (idx === players.length - 1) && numberOfEmptySlots === 0 ? "auto" : undefined,
                  }}
                  playerState={playerState}
                  showHost={showHost}
                  showNames={showNames}
                  showScores={showScores}
                  showUserAnswers={showUserAnswers}
                  showUserScoreDiff={showUserScoreDiff}
                  playerDefaultImage={playerDefaultImage}
                  score={playerScore}
                  rotationValue={rotationValues[idx % rotationValues.length]}
                  showTick={showTick}
                />
              );
            } else {
              return (
                <div
                  key={idx}
                  className="player-score-container icon-only"
                  style={{
                    transform: "scale(1)",
                    border: "none",
                  }}
                  // style={{ backgroundColor: playerState.getState("profile")?.color }}
                  // showNames={showNames}
                  // showScores={showScores}
                  // mobileMode={mobileMode}
                  // initialScore={initialScore}
                  // playersList={playersList}
                  // noAbsolute={noAbsolute}
                  // numberOfEmptySlots={numberOfEmptySlots}
                  // scrollable={scrollable}
                  // alignTo={alignTo}
                  // position={position}
                >
                  {/* // empty avatar */}
                  <div className="empty-avatar-holder"></div>
                </div>
              );
            }
          })}
          {/* {
        [
          ...Array(numberOfEmptySlots)
        ].map((value, idx) => {
          return <AvatarIconOnlyItem  
          playerContainerStyles={{
            // margin: "auto",
            // marginLeft: (idx === 0) && players.length === 0 ? "auto" : undefined,
            // marginRight: idx === players.length - 1 ? "auto" : undefined,
          }}
          />
        })
      } */}
        </div>
      </div>
    );
  } else {
    return (
      <Element>
        <PlayersAutofitHorizontal
          gap={10}
          playerWidth={"3rem"}
          playerElement={({ playerState, index: idx }) => {
            const playerScore =
              initialScore +
              (playerState.getState("profile")?.points ||
                playerState.getState("profile")?.score ||
                0);

            // const rotationValues = [-8, 6, -4, 9];
            const rotationValues = [0, 0, 0, 0];
            return (
              <AvatarIconOnlyItem
                key={idx}
                containerRef={idx === 0 ? playerAvatarRef : undefined}
                playerContainerStyles={{
                  animation: `0.5s bounce-in ${
                    0.1 + idx * 0.25
                  }s ease-in-out forwards`,
                  // margin: "auto",
                  // marginLeft: idx === 0 ? "auto" : undefined,
                  // marginRight: (idx === players.length - 1) && numberOfEmptySlots === 0 ? "auto" : undefined,
                }}
                playerState={playerState}
                showNames={showNames}
                showScores={showScores}
                showUserAnswers={showUserAnswers}
                showUserScoreDiff={showUserScoreDiff}
                score={playerScore}
                rotationValue={rotationValues[idx % rotationValues.length]}
                showTick={showTick}
              />
            );
          }}
        ></PlayersAutofitHorizontal>
      </Element>
    );
  }
}

const AvatarIconOnlyItem = ({
  containerRef,
  playerState,
  // score,
  rotationValue,
  showNames,
  showScores,
  showHost,
  showUserAnswers,
  showUserScoreDiff,
  playerContainerStyles,
  playerDefaultImage,
  showTick,
  ...rest
}) => {
  const myRef = useRef();

  const actualRef = containerRef || myRef;

  const score = useOtherPlayerState(playerState, "score", 0);
  const profile = useOtherPlayerState(playerState, "profile");
  const userAnswer = useOtherPlayerState(playerState, "round.userAnswer");
  const done = useOtherPlayerState(playerState, "round.done");

  const host = useGlobalState("host");

  useEffect(() => {
    // alert ("check console");
    console.log("done:", done);
    console.log(
      `playerState.getRoundState("done")`,
      playerState.getRoundState("done")
    );
  }, [playerState, done]);

  const userAnswerDisplay = userAnswer?.display || userAnswer;

  const userScoreDiff = useOtherPlayerState(playerState, "round.userScoreDiff");

  console.log("RERENDER AVATAR", score, profile);
  return (
    <div
      ref={actualRef}
      className="player-score-container icon-only"
      style={playerContainerStyles}
      // style={{ backgroundColor: playerState.getState("profile")?.color }}
      {...rest}
    >
      {playerState ? (
        <AvatarIcon
          key={playerState.id}
          playerState={playerState}
          style={{
            borderWidth: "0.4rem",
          }}
          defaultImage={playerDefaultImage}
        />
      ) : (
        // empty avatar
        <div className="empty-avatar-holder"></div>
      )}

      {showTick &&
        done !== undefined &&
        done !== false &&
        done !== -1 &&
        !showUserAnswers &&
        playerState.getRoundState("done") && (
          <div
            key={"showUserAnswers" + showUserAnswers + done}
            className="tick"
          >
            <img src={`${tickImg}`} alt="" />
          </div>
        )}

      {showHost && host === playerState.id && (
        <div className="isHost">
          <span>🧙🏻‍</span>
        </div>
      )}

      <div
        key={"userScoreDiff_" + userScoreDiff}
        className={`user-score-diff ${userScoreDiff} ${
          showUserScoreDiff ? "show" : "hide"
        }`}
      >
        {userScoreDiff}
      </div>
      <div
        className={`user-answer ${userAnswerDisplay || "no-answer"} ${
          showUserAnswers ? "show" : "hide"
        }`}
      >
        {userAnswerDisplay}
      </div>

      {/* { actualRef.current ? <FlyingReactions 
        reaction="🔥" 
        n={7} 
        fullscreen={false}
        direction={"vertical"}
        size={"2rem"} 
        xStart={-(actualRef.current.offsetWidth/2) - 10 + "px"} 
        xEnd={(actualRef.current.offsetWidth/2) - 10 + "px"}
      />
      :
      <></>
      } */}

      {showNames && (
        <div
          className="score-container cartoon-dialog-box"
          style={{
            rotate: `${rotationValue}deg`,
            animation: "bounce-in 0.2s",
          }}
        >
          <span>{profile?.name}</span>
        </div>
      )}
      {showScores && (
        <div
          key={profile?.name + "_" + score}
          className="score-container cartoon-dialog-box"
          style={{
            rotate: `${rotationValue}deg`,
            animation: "bounce-in 0.2s",
          }}
        >
          <span>{score || 0}</span>
        </div>
      )}
    </div>
  );
};

// for storybook
AvatarIconOnly.propTypes = {
  // pointAt: PropTypes.bool,
  text: PropTypes.string,
  // children: PropTypes.bool,
  expanded: PropTypes.bool,
  position: PropTypes.oneOf(["left", "center", "right"]),
  triggerAfter: PropTypes.number,
  // quickPositions: PropTypes.array,
  // animate: PropTypes.bool,
  // fingerPointerStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  // textSpanStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default AvatarIconOnly;
