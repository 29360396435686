import Multiplayer from "./multiplayer";

/*
t = 0 - Animation is just started. Zero time has passed
b = 200 - The starting position of the objects x-coordinate is 200
c = 300 - The object has to move 300 to the right, ending at 500
d = 1 - The object has one second to perform this motion from 200 to 500

from https://spicyyoghurt.com/tools/easing-functions
*/
function easeLinear(t, b, c, d) {
  return (c * t) / d + b;
}

export class UIfx {
  constructor(file, config) {
    const namespace = "uifx";
    const throttle = (fn, delay) => {
      let lastCall = 0;
      return function (...args) {
        const now = new Date().getTime();
        if (now - lastCall < delay) {
          return;
        }
        lastCall = now;
        return fn(...args);
      };
    };
    const validateURI = (file) => {
      if (!file) {
        throw Error('Requires valid URI path for "file"');
      } else return file;
    };
    const validateVolume = (volume) => {
      const message = '"Volume" must be an number between 0.0 and 1.0';

      if (volume && typeof volume !== "number") throw Error(message);
      if (volume < 0 || volume > 1) throw Error(message);

      return volume ? volume : 1.0;
    };
    const validateThrottleMs = (throttleMs) => {
      const message = '"throttleMs" must be a number greater than zero';

      if (throttleMs && typeof throttleMs !== "number") throw Error(message);
      if (throttleMs < 0) throw Error(message);

      return throttleMs ? throttleMs : 0;
    };
    const volume = validateVolume(config && config.volume);
    const throttleMs = validateThrottleMs(config && config.throttleMs);
    const loop = config && config.loop;
    const appendAudioElement = (file) => {
      // hack to force browser
      // to preload audio file

      // hash function: https://stackoverflow.com/a/8831937/11330825
      const hash = (str) => {
        var hash = 0;
        if (str.length === 0) {
          return hash;
        }
        for (var i = 0; i < str.length; i++) {
          var char = str.charCodeAt(i);
          hash = (hash << 5) - hash + char;
          hash = hash & hash; // Convert to 32bit integer
        }
        return Math.abs(hash);
      };
      const id = `${namespace}-${hash(file)}`;
      let audioElement = document.createElement("audio");

      audioElement.id = id;
      audioElement.src = file;
      audioElement.preload = "auto";
      if (loop) audioElement.loop = true;

      document.body.appendChild(audioElement);
      return { file, id, audioElement };
    };

    const audioData = appendAudioElement(validateURI(file));
    this.file = audioData.file;
    this.audioElement = audioData.audioElement;
    this.volume = volume;
    this.mute = false;
    this.throttleMs = throttleMs;
    this.play = throttleMs > 0 ? throttle(this.play, throttleMs) : this.play;
    this.validateVolume = validateVolume;
  }


  playLoadedAudio = (volume, audioElement) => {
    const _audioElement = audioElement || this.audioElement;
    _audioElement.volume = volume >= 0 && volume <= 1 ? volume : this.volume;
  
    const audioElementPromised = _audioElement.play();

    audioElementPromised
      .then(() => {
        // autoplay started, everyting is ok
      })
      .finally()
      .catch((error) => {
        console.log(`UIfx says: "had a problem playing file: ${this.file}"`);
      });
  } 

  play = (volume = 1) => {
    this.validateVolume(volume);

    const audioElement = this.audioElement || new Audio(this.file);
      audioElement.load();
      audioElement.addEventListener("loadeddata", () => {
        this.playLoadedAudio(volume, audioElement);
      });
    return this;
  };

  fadeInAndPlay = (duration, finalVolume = 1, onComplete) => {
    this.validateVolume(finalVolume);
    return new Promise((resolve) => {
      const audioElement = this.audioElement || new Audio(this.file);
      audioElement.load();
      if (onComplete) {
        audioElement.addEventListener("ended", onComplete);
      }
      audioElement.addEventListener("loadeddata", async () => {
        var vol = 0;
        var startDate = Date.now();
        audioElement.volume = 0;
        await audioElement.play();
        var intervalID = setInterval(() => {
          if (vol < finalVolume) {
            vol = Math.min(
              easeLinear(Date.now() - startDate, 0, finalVolume, duration),
              1
            );
            this.setVolume(parseFloat(vol.toFixed(2)));
          } else {
            // Stop the setInterval when finalVolume is reached
            clearInterval(intervalID);
            resolve();
          }
        }, 100);
      });
    });
  };

  setVolume = (volume) => {
    this.volume = this.validateVolume(volume);
    if (this.audioElement) {
      this.audioElement.volume = this.volume;
    }
    return this;
  };

  stop = () => {
    if (this.audioElement) {
      this.audioElement.pause();
      this.audioElement.currentTime = 0;
    }
  };

  setMute = (mute) => {
    this.mute = mute;
    if (this.audioElement) {
      this.audioElement.muted = mute;
    }
  };

  fadeOutAndStop = (duration) => {
    if (!this.audioElement) return;
    return new Promise((resolve) => {
      var vol = this.volume;
      var initalVol = this.volume;
      var startDate = Date.now();
      var intervalID = setInterval(() => {
        if (vol > 0) {
          vol = Math.max(
            easeLinear(Date.now() - startDate, initalVol, -initalVol, duration),
            0
          );
          this.audioElement.volume = vol;
        } else {
          // Stop the setInterval when 0 is reached
          clearInterval(intervalID);
          this.stop();
          resolve();
        }
      }, 100);
    });
  };
}

var SOUNDCACHE = {};

export default function WrappedUIfx(file, config, canBePaused = true) {
  console.log("file:", file);
  let sound;
  if (!config) {
    sound = SOUNDCACHE[file];
    console.log("SOUNDCACHE:", SOUNDCACHE);
    console.log("sound:", sound);
    if (!sound) {
      sound = new UIfx(file);
      SOUNDCACHE[file] = sound;
    }
  } else {
    sound = new UIfx(file, config);
  }

  if (canBePaused) {
    const muted = Multiplayer().getState("muted");
    if (muted) sound.setMute(true);
  }
  
  return sound;
}

window.UIFX = WrappedUIfx;
