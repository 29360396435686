import { useGlobalRoundState, useGlobalState } from "../../hooks/multiplayer";
import Multiplayer from "../../modules/multiplayer";
// import GamesMenu from "../../components/GamesMenu";
import TouchJoystick from "../../modules/controllers/touch";
import TwoButtonJoystick from "../../modules/controllers/twobuttons";
import GyroController from "../../modules/controllers/gyro";
import { useStateIsSetForAllPlayers } from "../../hooks/multiplayer";
import { useEffect, useState } from "react";
import gameDimensionConfig from "../../components/GameRenderer/config";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import PhaserRenderer from "../GameRenderer";
import Timer from "../Timer";
import "./style.scss";
import Toast from "../Toast";
import StartButtonWithGyroPermissions from "../StartButtonWithGyroPermissions";
import AvatarIconStrip from "../AvatarIconStrip";
import AvatarIconOnly from "../AvatarIconOnly";
import NativeMethods from "../../modules/NativeMethods";

// auto scales the children to fit the screen. Also renders the controller if this is a controller screen.
export default function SceneRenderer({
  gameConfig,
  sceneConfig,
  children,
  portrait,
  renderControllerInSceneWrapper,
  triviaConfig,
  resetTriviaGameConfig,
}) {
  // console.log("sceneConfig", sceneConfig);
  const winner = useGlobalState("winner");
  const windowSize = useWindowDimensions();
  const [renderPhaser, setRenderPhaser] = useState(true);
  const allPlayersHaveGivenGyroPermission = useStateIsSetForAllPlayers(
    "gyroPermissionGiven"
  );

  const currentRound = useGlobalState("_round");
  const stopTimer = useGlobalState("stopTimer");
  const timer = useGlobalState("timer");
  const timerRound = useGlobalRoundState("timer");

  useEffect(() => {
    console.log("[useEffect] currentRound -> ", currentRound);
    if (stopTimer) {
      return;
    }

    multiplayer.setState("endTimer", undefined);

    const setTimer = (_timer) => {
      if (sceneConfig?.timer.type === "round") {
        multiplayer.setRoundState("timer", _timer);
      } else if (timerRound) {
        multiplayer.setState("timer", _timer);
      }
    };

    var intervalId;
    if (sceneConfig?.timer) {
      // try-to: clear previous timer
      if (window.timerIntervalId) {
        window.clearInterval(window.timerIntervalId);
        clearInterval(window.timerIntervalId);
      }

      // Initialize a new timer on each round
      intervalId = Timer({
        ...{
          isNonDisplay: true,
          setTimer: setTimer,
          durationInMs: (sceneConfig?.timer.duration || 30) * 1000,
          onAllFinish: (x) => {
            setTimer(x);
            setTimeout(() => {
              multiplayer.setState("endTimer", 1 + Math.random());
            }, 500);
          },
        },
        ...(sceneConfig?.timer?.nonDisplayConfig || {}),
      });
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
      if (window.timerIntervalId) {
        window.clearInterval(window.timerIntervalId);
        clearInterval(window.timerIntervalId);
      }
    };

    // @TODO: add currentRound in the dependency array
  }, [currentRound, stopTimer]);

  const [gyroPermissionGiven, setGyroPermissionGiven] = useState(false);
  var scale = Math.min(
    windowSize.width / gameDimensionConfig.baseWidth,
    windowSize.height / gameDimensionConfig.baseHeight
  );

  if (portrait) {
    scale = Math.min(
      windowSize.height / gameDimensionConfig.baseWidth,
      windowSize.width / gameDimensionConfig.baseHeight
    );
  }
  const multiplayer = Multiplayer();
  let containerStyle = {};
  if (sceneConfig?.background?.color) {
    containerStyle.backgroundColor = sceneConfig.background.color;
  }
  if (sceneConfig?.background?.style) {
    containerStyle = sceneConfig?.background?.style;
  }

  let controllerContainerStyle =
    sceneConfig?.controller?.background?.style || {};
  if (sceneConfig?.controller?.background?.color) {
    controllerContainerStyle.backgroundColor =
      sceneConfig?.controller.background.color;
  }

  let avatarComponent;
  if (sceneConfig?.avatars) {
    const avatarConfig = sceneConfig?.avatars;
    if (avatarConfig.type === "strip") {
      const stripConfig = avatarConfig?.config || {};
      // alert ("stripConfig "+stripConfig.showScores)
      avatarComponent = (
        <AvatarIconStrip
          showNames={avatarConfig.showNames}
          mobileMode={!multiplayer.isSpectator}
          {...stripConfig}
        />
      );
    } else if (avatarConfig.type === "icon") {
      console.log("[SceneRenderer] avatarConfig", avatarConfig);
      console.log("[SceneRenderer] avatarConfig.config", avatarConfig.config);
      avatarComponent = (
        <AvatarIconOnly
          showNames={avatarConfig.showNames}
          mobileMode={
            !multiplayer.singlePlayerMode &&
            !multiplayer.isSpectator &&
            NativeMethods.isController()
          }
          showScores={avatarConfig.showScores}
          initialScore={avatarConfig.initialScore}
          alignTo={multiplayer.isSpectator ? "auto" : avatarConfig.alignTo}
          {...{
            ...avatarConfig.config,
            ...(multiplayer.isSpectator
              ? avatarConfig.spectatorConfig
              : {
                  ...avatarConfig.playerConfig,
                  ...(multiplayer.isHost ? avatarConfig.hostConfig : {}),
                }),
          }}
        />
      );
    }
  }

  // useEffect(() => {
  //   console.log("timer || timerRound -> ", timer || timerRound);
  // }, [timer, timerRound])

  useState(() => {
    window.refreshPhaser = () => {
      setRenderPhaser(false);
      setTimeout(() => {
        setRenderPhaser(true);
      }, 300);
    };
    var controller = null;
    if (!multiplayer.isSpectator && sceneConfig && sceneConfig.controller) {
      if (sceneConfig.controller.type === "joystick") {
        controller = new TouchJoystick(sceneConfig.controller.config);
      }
      if (sceneConfig.controller.type === "gyro") {
        // controller = new GyroController(sceneConfig.controller.config);
      }
      if (sceneConfig.controller.type === "onebutton") {
        controller = new TwoButtonJoystick({
          onebutton: true,
          ...sceneConfig.controller.config,
        });
      }
      if (controller) {
        multiplayer.attachController(controller);
      }
    }
    if (sceneConfig.autoscaling === false) {
      document.querySelector("html").style = "font-size: initial";
    } else {
      document.querySelector("html").style = "";
    }

    console.log("useState CALLED", sceneConfig);
    return () => {
      console.log("Detaching controller?? CALLED", multiplayer.isSpectator);
      if (!multiplayer.isSpectator) {
        const controller = multiplayer.detachControllerLegacy();
        console.log("Detaching controller CALLED");
        if (controller) controller.destroy();
      }
    };
  }, [sceneConfig]);

  let GamesMenu = null;

  if (!gameConfig.external) {
    GamesMenu = require("../../components/GamesMenu").default;
  }

  let gyroPermissionUI = null;
  if (sceneConfig?.controller?.type === "gyro") {
    if (multiplayer.isSpectator && !allPlayersHaveGivenGyroPermission) {
      gyroPermissionUI = (
        <div className="scene-renderer">
          <Toast visible center>
            Waiting for all players to give permission to use device sensors.
          </Toast>
        </div>
      );
    } else if (!multiplayer.isSpectator && !gyroPermissionGiven) {
      gyroPermissionUI = (
        <div className="scene-renderer">
          <Toast visible center>
            <StartButtonWithGyroPermissions
              onPermissionGranted={() => {
                setGyroPermissionGiven(true);
                multiplayer
                  .getMyPlayerState()
                  .setState("gyroPermissionGiven", true);
                const controller = new GyroController(
                  sceneConfig.controller.config
                );
                multiplayer.attachController(controller);
              }}
            />
          </Toast>
        </div>
      );
    } else if (!multiplayer.isSpectator && !allPlayersHaveGivenGyroPermission) {
      gyroPermissionUI = (
        <div className="scene-renderer">
          <Toast visible center>
            Waiting for others players to give permission to use device sensors.
          </Toast>
        </div>
      );
    }
  }

  let PhaserScene = null;
  let BackgroundComponent = sceneConfig?.background?.component || (() => <></>);
  if (multiplayer.isSpectator || !children || renderControllerInSceneWrapper) {
    // tv/laptop screen
    if (sceneConfig.phaser && renderPhaser) {
      PhaserScene = (
        <PhaserRenderer
          key="game"
          fit
          // backgroundColor="#ff00ff"
          gameInfo={gameConfig}
          scenes={[sceneConfig.phaser.scene]}
          physics={sceneConfig.phaser.physics}
        />
      );
    }
    return (
      <>
        <div className="avatar-container">{avatarComponent}</div>
        <BackgroundComponent />
        {gyroPermissionUI ? (
          gyroPermissionUI
        ) : (
          <div style={containerStyle} className="scene-renderer">
            {(timer || timerRound) !== undefined &&
              (!sceneConfig?.timer?.showTimer ||
                sceneConfig?.timer?.showTimer !== false) && (
                <div
                  // key={timerRound ? timerRound + "_" : "timer_container"}
                  style={{
                    top: "1rem",
                    left: "1rem",
                    ...sceneConfig?.timer?.style,
                  }}
                  className={`timer-container ${sceneConfig?.timer?.className}`}
                >
                  <Timer
                    isNonDisplay={false}
                    height={sceneConfig?.timer?.height || "6rem"}
                    width={sceneConfig?.timer?.width || "6rem"}
                    color={sceneConfig?.timer?.color || "#00000090"}
                    timer={timer || timerRound}
                    durationInMs={(sceneConfig?.timer?.duration || 30) * 1000}
                    {...(sceneConfig?.timer?.displayConfig || {})}
                  />
                </div>
              )}
            {GamesMenu && (
              <GamesMenu
                key="gamesmenu"
                gameInfo={gameConfig}
                hideRoomCode
                showRestart={winner && multiplayer.isHost}
              />
            )}
            {sceneConfig.phaser && !sceneConfig.phaser.onTop
              ? PhaserScene
              : false}
            <div
              className={
                sceneConfig.autoscaling === false
                  ? undefined
                  : "scene-renderer-content"
              }
              style={
                sceneConfig.autoscaling === false
                  ? undefined
                  : portrait //inverse the height/width for portrait
                  ? {
                      height: gameDimensionConfig.baseWidth,
                      width: gameDimensionConfig.baseHeight,
                      transform: `translate(-50%, -50%) scale(${scale})`,
                    }
                  : {
                      width: gameDimensionConfig.baseWidth,
                      height: gameDimensionConfig.baseHeight,
                      transform: `translate(-50%, -50%) scale(${scale})`,
                    }
              }
            >
              {multiplayer.isSpectator ? children : false}
              {renderControllerInSceneWrapper &&
              !multiplayer.isSpectator &&
              sceneConfig.controller?.component
                ? sceneConfig.controller?.component()
                : false}
            </div>
            {sceneConfig.phaser && sceneConfig.phaser.onTop
              ? PhaserScene
              : false}
          </div>
        )}
      </>
    );
  } else {
    // player controller screen
    return (
      <>
        {gyroPermissionUI}
        {avatarComponent}
        <div
          style={
            gyroPermissionUI ? { display: "none" } : controllerContainerStyle
          }
          className="scene-renderer-controller"
        >
          {(timer || timerRound) !== undefined &&
            (!sceneConfig?.timer?.showTimer ||
              sceneConfig?.timer?.showTimer !== false) && (
              <div
                style={{
                  top: "1rem",
                  left: "1rem",
                  ...sceneConfig?.timer?.style,
                }}
                className={`timer-container ${sceneConfig?.timer?.className}`}
              >
                <Timer
                  isNonDisplay={false}
                  height={sceneConfig?.timer?.height || "6rem"}
                  width={sceneConfig?.timer?.width || "6rem"}
                  color={sceneConfig?.timer?.color || "#00000090"}
                  timer={timer || timerRound}
                  durationInMs={(sceneConfig?.timer?.duration || 30) * 1000}
                  {...(sceneConfig?.timer?.displayConfig || {})}
                />
              </div>
            )}
          {GamesMenu && (
            <GamesMenu
              key="gamesmenu"
              gameInfo={gameConfig}
              hideRoomCode
              showRestart={winner && multiplayer.isHost}
            />
          )}
          {sceneConfig.controller?.component({
            triviaConfig,
            resetTriviaGameConfig,
          })}
        </div>
      </>
    );
  }
}
