import PlayerState from "./playerState";
import SuperEventEmitter from "../superEventEmitter";
import BrowserStorage from "../localStorageWrapper";
import { getMyId, getMyPermanentId } from "../auth";
import { waitUntilStateIsSetForPlayer } from "./asyncHelpers";
import { MultiplayerConstants } from "./constants";
import log from "../log";
import { applyObjectPatch } from "symmetry";
import Analytics from "../../analytics";
import { nanoid } from "nanoid";
import md5 from "md5";

function jsonToUrlParams(json, keyPrefix) {
  return Object.keys(json)
    .map(function (k) {
      return (
        keyPrefix + encodeURIComponent(k) + "=" + encodeURIComponent(json[k])
      );
    })
    .join("&");
}

function getAllParamsFromOptions(sdkOptions) {
  const profileParams = sdkOptions?.profile
    ? jsonToUrlParams(sdkOptions.profile, "profile_")
    : "";
  const reconnectGracePeriodParams = sdkOptions?.reconnectGracePeriod
    ? `reconnectGracePeriod=${sdkOptions.reconnectGracePeriod}`
    : "";
  const maxPlayersPerRoomParams = sdkOptions?.maxPlayersPerRoom
    ? `maxPlayersPerRoom=${sdkOptions.maxPlayersPerRoom}`
    : "";

  // combine all params
  const allParams = [
    profileParams,
    reconnectGracePeriodParams,
    maxPlayersPerRoomParams,
  ]
    .filter(Boolean)
    .join("&");
  return allParams;
}

export default class Connection extends SuperEventEmitter {
  constructor({
    isHost,
    roomId,
    isSpectator,
    letEveryoneWriteState = false,
    enableDeltaCompression = false,
    sdkOptions,
  }) {
    super();
    this.hostname = "wss://ws.joinplayroom.com";
    // this.hostname = "ws://127.0.0.1:8787";
    if (process?.env?.REACT_APP_SERVER) {
      this.hostname = process.env.REACT_APP_SERVER;
    }
    this.isHost = isHost;
    this.isSpectator = isSpectator;

    this.roomId = roomId;

    // for bunch version
    if (this.roomId && this.roomId.length > 8) {
      this.roomId = md5(this.roomId);
    }

    this.myId = getMyId();
    this.renderServerId = null;
    this.lastSeenTimestamp = 0;
    this.lastUpdateOrderSeen = 0;
    this.lastUpdateSyncMessageSeen = {};
    this.enableDeltaCompression = enableDeltaCompression;
    this.sdkOptions = sdkOptions;
    this.playerStates = {};
    this.autoAdmitPlayers = true;
    this.waitingPlayerStates = {};
    this.syncIntervalTime = MultiplayerConstants.SyncIntervalTime;
    this.pendingDeletionPlayerIds = [];
    this.spectatorStates = {};
    this.bootDate = Date.now();
    this.wsHeartbeatInterval = 0;
    this.iUpdatedStateAt = {};
    this.ignoreNextSync = false;
    this.requestSyncs = true; // useful for running tests without syncs
    this.joinWebsocket();
    this.globalState = {};
    this.letEveryoneWriteState = letEveryoneWriteState;
    Analytics.identify(getMyPermanentId());
  }

  isRenderServer() {
    return this.myId === this.renderServerId;
  }

  getBootDate() {
    return this.bootDate;
  }

  broadcastGlobalState(forceSendToPlayers = false) {
    var state = { ...this.globalState, __players: {} };
    Object.keys(this.playerStates).forEach((playerId) => {
      state.__players[playerId] = this.playerStates[playerId].getState();
    });

    if (Object.keys(this.waitingPlayerStates).length > 0) {
      state.waitingPlayers = [];
      Object.keys(this.waitingPlayerStates).forEach((playerId) => {
        state.waitingPlayers.push(this.waitingPlayerStates[playerId].id);
      });
    } else {
      delete state.waitingPlayers;
    }

    // always sent via websocket
    let payload = { sync: state, o: Date.now() - this.bootDate };
    if (forceSendToPlayers) {
      payload.force = true;
    }
    this.websocketSend(JSON.stringify(payload));
  }

  joinWebsocket() {
    let ws = new WebSocket(
      this.hostname +
        "/api/room/" +
        this.roomId +
        "/websocket/" +
        (this.myId || "new") + // re-use the id I had previously (if any)
        (this.isHost ? "/host" : "") + // make me host (if isHost is set)
        (this.isSpectator ? "/spectator" : "") +
        (this.sdkOptions ? "?" + getAllParamsFromOptions(this.sdkOptions) : "")
    ); // make me host (if isSpectator is set)

    ws.addEventListener("open", (event) => {
      this.ws = ws;
      this.wsHeartbeatInterval = setInterval(() => {
        // apart from heartbeat, host also sends global state (and players) in a 'sync' call every heartbeat
        if (this.isRenderServerOrHostIfNotCasting) {
          this.websocketSend(JSON.stringify({ beat: Date.now() }));
          this.broadcastGlobalState();
        } else {
          // if I am not host, ask backend to send me delta of global state (if delta compression is enabled)
          if (this.requestSyncs) {
            if (this.enableDeltaCompression) {
              this.websocketSend(
                JSON.stringify({
                  beat: Date.now(),
                  syncO: this.lastUpdateOrderSeen,
                })
              );
            } else {
              this.websocketSend(JSON.stringify({ beat: Date.now() }));
            }
          }
        }
      }, this.syncIntervalTime);
    });

    ws.addEventListener("error", (event) => {
      throw new Error(event.error);
    });

    ws.onerror = (e) => {
      throw new Error(
        e.message +
          " " +
          (this.hostname +
            "/api/room/" +
            this.roomId +
            "/websocket/" +
            (this.myId || "new") + // re-use the id I had previously (if any)
            (this.isHost ? "/host" : "") + // make me host (if isHost is set)
            (this.isSpectator ? "/spectator" : ""))
      );
    };

    ws.addEventListener("message", (event) => {
      let data = JSON.parse(event.data);
      if (data.error) {
        this.emit("error", { type: "websocket", error: data.error });
      } else if (data.newId) {
        this.myId = data.newId;
        BrowserStorage.set("myId", data.newId);
        // only when we receive newId message, we know server accepted our connection to this room.
        // create my own player instance
        if (!this.playerStates[this.myId] && !this.isSpectator)
          this.playerStates[this.myId] = this.createPlayerState(this.myId);

        // if the server sent us profile, set that.
        if (data.profile) {
          var curProfile =
            this.playerStates[this.myId].getState("profile") || {};
          this.playerStates[this.myId].setState("profile", {
            ...curProfile,
            ...data.profile,
          });
        }

        if (data.bootDate) {
          this.bootDate = data.bootDate;
        }
        // emit state to server (specially player state) if we are host, server's state is
        // empty at this point.
        if (this.isHost) {
          this.emit("before_initial_sync");
          this.broadcastGlobalState();
          this.once("sync", (data) => {
            this.emit("connected");
          });
        } else {
          this.emit("connected");
        }
      } else if (data.joined && this.isRenderServerOrHostIfNotCasting) {
        if (this.autoAdmitPlayers) {
          if (!this.playerStates[data.joined]) {
            this.playerStates[data.joined] = this.createPlayerState(
              data.joined,
              false,
              data.proxyBy
            );
          }
          this.emitPlayerJoined(this.playerStates[data.joined]);
          // this.emit("joined", this.playerStates[data.joined]);
          // this.emit("players", this.playerStates);
        } else {
          // Silently admit player if the player was already in game, left briefly (e.g. due to network issue) and rejoined
          if (
            this.pendingDeletionPlayerIds.includes(data.joined) &&
            this.playerStates[data.joined]
          ) {
            this.playerStates[data.joined].startWebrtc();
          } else {
            this.waitingPlayerStates[data.joined] = this.createPlayerState(
              data.joined
            );
          }
          if (this.pendingDeletionPlayerIds.includes(data.joined)) {
            this.pendingDeletionPlayerIds =
              this.pendingDeletionPlayerIds.filter(
                (pid) => pid !== data.joined
              );
          }
        }
        // Send latest sync so the joined player has the newest state
        // this.broadcastGlobalState(true);
      } else if (data.renderServerUpdate) {
        if (this.renderServerId !== data.renderServerUpdate) {
          this.renderServerId = data.renderServerUpdate;
          this.bootDate = data.bootDate;
          // reconnect all webrtc connections
          if (this.isRenderServer()) {
            Object.keys(this.playerStates).forEach((pid) => {
              this.playerStates[pid].startWebrtc();
            });
            Object.keys(this.spectatorStates).forEach((pid) => {
              this.spectatorStates[pid].startWebrtc();
            });
          } /*if (!this.isSpectator)*/ else {
            this.playerStates[this.myId].startWebrtc();
          }
        }
      } else if (data.hostUpdate) {
        this.isHost = data.hostUpdate === this.myId;
        this.emit("host_updated", this.isHost);
        // reconnect all webrtc connections
        if (this.isHost && this.isRenderServerOrHostIfNotCasting) {
          Object.keys(this.playerStates).forEach((pid) => {
            this.playerStates[pid].startWebrtc();
          });
        } else if (!this.isSpectator) {
          this.playerStates[this.myId].startWebrtc();
        }
      } else if (data.spectator) {
        if (!this.spectatorStates[data.spectator]) {
          this.spectatorStates[data.spectator] = this.createPlayerState(
            data.spectator,
            true
          );
          this.emit("spectator_joined", this.spectatorStates[data.spectator]);
        }
      } else if (data.quit) {
        if (
          this.playerStates[data.quit] &&
          this.isRenderServerOrHostIfNotCasting &&
          this.autoAdmitPlayers
        ) {
          this.playerStates[data.quit].disconnect();
        }
        if (
          this.playerStates[data.quit] &&
          this.isRenderServerOrHostIfNotCasting &&
          !this.autoAdmitPlayers
        ) {
          this.pendingDeletionPlayerIds.push(data.quit);
        }
        if (this.waitingPlayerStates[data.quit])
          this.waitingPlayerStates[data.quit].disconnect();
        log("pquit", data.quit, this.renderServerId);
        if (this.renderServerId === data.quit) {
          // this.disconnect(); // casting turned off, quit to homepage.
        }
        // if (this.isRenderServerOrHostIfNotCasting) {
        //   this.broadcastGlobalState(true);
        // }
      } else if (data.signal) {
        // if I am host, connect webrtc with this new player
        // skip if this message is from myself
        if (
          this.isRenderServerOrHostIfNotCasting &&
          this.myId !== data.id &&
          (this.playerStates[data.id] || this.spectatorStates[data.id])
        ) {
          log(
            "signal to host",
            data.signal,
            `from ${this.playerStates[data.id] ? "player" : ""}${
              this.spectatorStates[data.id] ? "spectator" : ""
            }`
          );
          if (this.playerStates[data.id])
            this.playerStates[data.id].signal(data.signal);
          if (this.spectatorStates[data.id])
            this.spectatorStates[data.id].signal(data.signal);
        } else if (
          !this.isRenderServerOrHostIfNotCasting &&
          this.myId === data.for
        ) {
          log("signal to client", data.signal);
          if (this.playerStates[this.myId])
            this.playerStates[this.myId].signal(data.signal);
          if (this.spectatorStates[this.myId])
            this.spectatorStates[this.myId].signal(data.signal);
        }
      } else if (data.pinput && this.isRenderServerOrHostIfNotCasting) {
        // player input coming from websocket, maybe their webrtc failed
        this.passPlayerInput(data.id, data.pinput);
      } else if (data.pong && this.isRenderServer()) {
        if (this.playerStates[data.id])
          this.playerStates[data.id].handlePingResponse(data);
      } else if (
        data.ping &&
        !this.isRenderServer() &&
        data.for === this.myId
      ) {
        this.websocketSend(
          JSON.stringify({
            pong: data.ping,
          })
        );
      } else if (data.pstate) {
        // player state update arrived, the state is set by player OR host. We just update local state.
        // this.ignoreNextSync = true;
        this.setPlayerState(data);
      } else if (data.gstate) {
        // this.ignoreNextSync = true;
        this.setLocalStateFromServerGlobalState(data.gstate[0], data.gstate[1]);
      } else if (data.sync) {
        this.emit("sync", data.sync);
        if (!this.isRenderServerOrHostIfNotCasting) {
          this.setFullLocalState(data.sync, data.o);
        }
      } else if (data.dsync) {
        // delta sync, apply patch
        const update = applyObjectPatch(
          this.lastUpdateSyncMessageSeen,
          data.dsync
        );
        this.emit("sync", update);
        this.emit("dsync", update);
        this.setFullLocalState(update, data.o);
      } else {
        // A regular message.
        if (data.timestamp > this.lastSeenTimestamp) {
          this.emit("message", { type: "reliable", data });
          this.lastSeenTimestamp = data.timestamp;
        }
      }
    });

    ws.addEventListener("close", (event) => {
      log("WebSocket closed, reconnecting:", event.code, event.reason);
      clearInterval(this.wsHeartbeatInterval);
      if (event.code === 4000) {
        this.emit("permission_error");
        this.disconnect(event.code);
      } else if (event.code === 4001 || event.code === 4002) {
        // host has left the room
        this.disconnect(event.code);
      } else {
        this.disconnect(event.code);
      }
    });
    ws.addEventListener("error", (event) => {
      log("WebSocket error, reconnecting:", event, typeof event);
      clearInterval(this.wsHeartbeatInterval);
    });
  }

  get isRenderServerOrHostIfNotCasting() {
    if (this.isRenderServer()) return true;
    if (!this.getState("casting") && this.isHost) return true;
    return false;
  }

  setAutoAdmitPlayers(autoAdmit) {
    if (!this.isRenderServerOrHostIfNotCasting) return;
    if (this.autoAdmitPlayers === autoAdmit) return;
    log("setAutoAdmitPlayers", autoAdmit);
    if (!this.autoAdmitPlayers && autoAdmit) {
      // admit all waiting players
      if (Object.keys(this.waitingPlayerStates).length > 0) {
        Object.keys(this.waitingPlayerStates).forEach((pid) => {
          this.playerStates[pid] = this.waitingPlayerStates[pid];
          this.emitPlayerJoined(this.playerStates[pid]);
          // this.emit("joined", this.playerStates[pid]);
        });
        // this.emit("players", this.playerStates);
      }

      this.waitingPlayerStates = {};
      this.pendingDeletionPlayerIds.forEach((pid) => {
        if (this.playerStates[pid]) {
          this.playerStates[pid].disconnect();
        }
      });
      this.pendingDeletionPlayerIds = [];
    }
    this.autoAdmitPlayers = autoAdmit;
  }

  addProxyPlayer() {
    if (!this.isRenderServerOrHostIfNotCasting) return;
    var id = nanoid(9);
    this.broadcast({ joined: id }, true);
    this.playerStates[id] = this.createPlayerState(id, false, true);
    // this.emitPlayerJoined(this.playerStates[id]);
    return this.playerStates[id];
  }

  removeProxyPlayer(playerId) {
    if (this.playerStates[playerId]) {
      this.broadcast({ quit: playerId }, true);
      // this.playerStates[playerId].disconnect();
      // delete this.playerStates[playerId];
    }
  }

  createPlayerState(id, isSpectator, isProxyPlayer = false) {
    var p = new PlayerState({
      websocketSend: (data) => this.websocketSend(data),
      id: id,
      myId: this.myId,
      // isHost: this.isHost,
      isRenderServer: () => {
        return this.isRenderServer();
      },
      isRenderServerOrHostIfNotCasting: () => {
        return this.isRenderServerOrHostIfNotCasting;
      },
      playerIsSpectator: isSpectator,
      playerIsProxy: isProxyPlayer,
      broadcastUnreliable: this.broadcastUnreliable.bind(this),
      setPlayerState: this.setPlayerState.bind(this),
      getBootDate: this.getBootDate.bind(this),
      avatarList: this.sdkOptions?.avatars,
    });

    if (this.isRenderServerOrHostIfNotCasting || id === this.myId) {
      log(
        "startWebrtc::createPlayerState",
        id,
        this.isRenderServerOrHostIfNotCasting
      );
      p.startWebrtc();
    }

    p.on("quit", () => {
      log("pquit", p.id);
      delete this.playerStates[p.id];
      this.emit("players", this.playerStates);
      this.emit("player_quit", p.id);
      if (this.waitingPlayerStates[p.id]) {
        delete this.waitingPlayerStates[p.id];
      }
    });

    p.on("global_state", (data) => {
      this.setLocalState(data[0], data[1]);
    });
    return p;
  }

  emitPlayerJoined(p) {
    waitUntilStateIsSetForPlayer(p, "profile").then(() => {
      this.emit("joined", p);
      this.emit("players", this.playerStates);
    });
  }

  broadcastUnreliable(data) {
    Object.keys(this.playerStates).forEach((playerId) => {
      if (playerId !== this.myId) {
        try {
          log("broadcast unreliable", playerId, data);
          this.playerStates[playerId].send(data, false, true);
        } catch (e) {
          log(e);
        }
      }
    });
  }

  websocketSend(data) {
    try {
      this.ws.send(data);
    } catch (e) {
      log(e);
    }
  }

  broadcast(data, reliable) {
    if (reliable) {
      if (this.ws) this.websocketSend(JSON.stringify(data));
    } else {
      Object.keys(this.spectatorStates).forEach((spectatorId) => {
        if (spectatorId !== this.myId) {
          this.spectatorStates[spectatorId].send(data, reliable);
        }
      });
      Object.keys(this.playerStates).forEach((playerId) => {
        if (playerId !== this.myId) {
          this.playerStates[playerId].send(data, reliable);
        }
      });
    }
  }

  // used by non-hosts, to setState about other players
  setPlayerState(data) {
    if (this.playerStates[data.pstate])
      this.playerStates[data.pstate].setLocalState(
        data.d[0],
        data.d[1],
        data.o
      );
    if (this.waitingPlayerStates[data.pstate])
      this.waitingPlayerStates[data.pstate].setLocalState(
        data.d[0],
        data.d[1],
        data.o
      );
  }

  // used by host, triggered when user input is sent to us (via network or controller(for local player))
  passPlayerInput(playerId, input) {
    if (this.playerStates[playerId]) {
      this.playerStates[playerId].handleInput(input, true);
    } else if (this.waitingPlayerStates[playerId]) {
      this.waitingPlayerStates[playerId].handleInput(input, true);
    }
  }

  getState(key) {
    if (!key) return this.globalState || {};
    // To avoid mutating the state object, we return a copy of it.
    if (typeof this.globalState[key] === "object") {
      return JSON.parse(JSON.stringify(this.globalState[key]));
    }
    return this.globalState[key];
  }

  // public method to change state object (used by host only). This is then synced with all clients.
  setState(key, newState, reliable = true) {
    const newStateCopied =
      typeof newState === "object"
        ? JSON.parse(JSON.stringify(newState))
        : newState;
    const stateChanged = this.setLocalState(key, newStateCopied);
    if (
      (this.isHost || this.isRenderServer() || this.letEveryoneWriteState) &&
      stateChanged
    ) {
      this.iUpdatedStateAt[key] = Date.now();
      this.broadcast({ gstate: [key, newStateCopied] }, reliable);
    }
  }

  // update local state from the server global state
  setLocalStateFromServerGlobalState(key, newState) {
    switch (key) {
      case "round.timer": {
        const localTimerState = this.getState("round.timer");
        const updatedTimer =
          newState > localTimerState ? newState : localTimerState;
        this.setLocalState(key, updatedTimer);
        break;
      }
      default: {
        this.setLocalState(key, newState);
        break;
      }
    }
  }

  // just change local state without broadcasting
  setLocalState(key, newState) {
    if (JSON.stringify(this.globalState[key]) === JSON.stringify(newState))
      return false;

    if (newState === undefined || newState === null) {
      delete this.globalState[key];
    } else {
      this.globalState[key] = newState;
    }
    this.emit("state", this.globalState, key);
    return true;
  }

  // used to change all of local state to what came from server
  setFullLocalState(newState, updateOrder) {
    this.lastUpdateOrderSeen = updateOrder;
    this.lastUpdateSyncMessageSeen = JSON.parse(JSON.stringify(newState));
    // don't overwrite path of host unless the state update also has a path.
    if (this.isHost) {
      newState.path = newState.path || this.globalState.path;
    }
    var playersState = newState.__players || {};
    delete newState.__players;
    // If the state was changed very recently by someone other than renderserver (host player?).
    // We need to skip .sync that follows it
    // Why? Because the render server may have broadcasted 'sync' with an older state just now.
    // It's ok to skip a sync, there will ne another one in a few seconds.
    if (!this.ignoreNextSync) {
      Object.keys(newState).forEach((key) => {
        if (
          !this.iUpdatedStateAt[key] ||
          this.iUpdatedStateAt[key] + this.syncIntervalTime < Date.now()
        ) {
          this.setLocalState(key, newState[key]);
        }
      });

      // also check for any deleted state
      Object.keys(this.globalState).forEach((key) => {
        if (
          !this.iUpdatedStateAt[key] ||
          this.iUpdatedStateAt[key] + this.syncIntervalTime < Date.now()
        ) {
          if (!newState[key] && newState[key] !== 0) {
            this.setLocalState(key, undefined);
          }
        }
      });

      // now loop over players and set their local state
      Object.keys(playersState).forEach((playerId) => {
        if (!this.playerStates[playerId]) {
          this.playerStates[playerId] = this.createPlayerState(playerId);
          this.emitPlayerJoined(this.playerStates[playerId]);
          // this.emit("joined", this.playerStates[playerId]);
          // this.emit("players", this.playerStates);
        }
        this.playerStates[playerId].setFullLocalState(
          playersState[playerId],
          updateOrder
        );
      });

      // check for removed players
      Object.keys(this.playerStates).forEach((playerId) => {
        if (
          !playersState[playerId] &&
          (newState.waitingPlayers || []).indexOf(playerId) === -1
        ) {
          log("player removed in state, disconnecting them", playerId);
          this.playerStates[playerId].disconnect();
        }
      });
    }
    this.ignoreNextSync = false;
  }

  disconnect(eventCode) {
    log("disconnecting with eventCode:", eventCode);
    this.emit("disconnected", { eventCode: eventCode });
    Object.keys(this.playerStates).forEach((playerId) => {
      this.playerStates[playerId].disconnect({ eventCode: eventCode });
    });
    Object.keys(this.waitingPlayerStates).forEach((playerId) => {
      this.waitingPlayerStates[playerId].disconnect({ eventCode: eventCode });
    });
    if (this.ws) this.ws.close();
  }
}
