/*
state.js

This file describes all the reserved states which are necessary for
Playroom to run as a Platform. Any states other than these are most 
likely user-defined or game-specific states, and should be purged as 
soon as possible, if Playroom's official screens come up.
*/

const PlayroomStates = [
  {
    id: "__started",
    description: "SDK: Has the game been started by host.",
  },
  {
    id: "path",
    description:
      "The route where the RenderServer is at, and all clients must follow.",
  },
  {
    id: "winslog",
    description: "Maintains a log of wins during a playlist.",
  },
  {
    id: "playlist",
    description: "The current selected playlist by the user.",
  },
  {
    id: "playlistCurIndex",
    description: "The position where the current selected playlist is at.",
  },
  {
    id: "casting",
    description: "Is true if the host has successfully casted their screen",
  },
  {
    id: "muted",
    description:
      "A user->screen global which controls sound on the Spectator Screen.",
  },
  {
    id: "skip",
    description:
      "A user->screen global which controls whether or not the user has skipped the autoplay screen.",
  },
  {
    id: "iframeUrl",
    description: "The route of the external game that is loaded.",
  },
  {
    id: "id",
    description: "4-digit Room ID.",
  },
  {
    id: "uid",
    description: "This session's UUID.",
  },
  {
    id: "meta",
    description: "Room metadata including creator's information.",
  },
];

const PlayerStates = [
  {
    id: "id",
    description: "The player's ID in this Playroom session.",
  },
  {
    id: "profile",
    description:
      "Contains the player's basic information, such as name, profile picture, etcetera.",
  },
  {
    id: "quit",
    description: "A user event which declares that a player has left the game.",
  },
  {
    id: "p",
    description: "The player's current ping.",
  },
];

export const resetAllStatesOtherThan = (stateHolder, type) => {
  const currentState = stateHolder.getState();
  let lookupArray = [];

  if (type === "playerState") {
    lookupArray = PlayerStates;
  } else if (typeof type === Object) {
    // if type is an Object (e.g. an array), set it to lookup. What could go wrong? :)
    lookupArray = type;
  } else {
    // by default, use PlayroomStates
    lookupArray = PlayroomStates;
  }

  // extract just the IDs
  lookupArray = lookupArray.map((state) => state.id);

  console.log("[to reset] lookupArray:", lookupArray);
  console.log("[to reset] currentState:", currentState);

  // if key not found in lookup array, unset it
  Object.keys(currentState).forEach((key) => {
    if (!lookupArray.includes(key)) {
      console.log("[to reset] key:", key);
      console.log("[to reset] currentState[key]:", currentState[key]);
      stateHolder.setState(key, undefined);
    }
  });
};

export const resetAllNonPlayroomStates = (multiplayer) => {
  const players = multiplayer.getPlayers();

  // reset all non-reserved states
  resetAllStatesOtherThan(multiplayer);

  // reset each player's non-reserved states
  if (players) {
    Object.keys(players).forEach((key, idx) => {
      resetAllStatesOtherThan(players[key], "playerState");
    });
  }
};

export default PlayroomStates;
