import Multiplayer from "../../modules/multiplayer";
import soundOnImg from "../../imgs/soundon.png";
import soundOffImg from "../../imgs/soundoff.png";
import {
  usePlayersList,
  useGlobalState,
  useGlobalRoundState,
  usePlayerState,
} from "../../hooks/multiplayer";

export const StartScreenButtons = ({
  startText = "Start Game",
  playInstructionsRef,
  playInstructions,
  onPlayInstructions,
  onStart,
  children,
}) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {onPlayInstructions ? (
        <button
          ref={playInstructionsRef}
          onClick={onPlayInstructions}
          className="button-black"
          style={{
            padding: "2rem 1rem",
            marginBottom: "2rem",
            fontFamily: "'Russo One'",
            width: "17.5rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: "inherit",
          }}
        >
          <img
            src={playInstructions ? soundOffImg : soundOnImg}
            style={{
              marginRight: "1rem",
              height: "1.5rem",
              width: "auto",
            }}
          />{" "}
          {playInstructions ? "Hide " : "Play "}
          Instructions
        </button>
      ) : (
        <></>
      )}
      {onStart ? (
        <button
          // className="space-container space-btn"
          className="button-black"
          onClick={onStart}
          style={{
            padding: "2rem 1rem",
            fontFamily: "'Russo One'",
            width: "17.5rem",
            marginBottom: "20rem",
            fontSize: "1rem",
          }}
        >
          {startText}
        </button>
      ) : (
        <></>
      )}
      {children}
    </div>
  );
};

export const StartScreenOverlay = ({ isBackgroundBlur, children }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backdropFilter: isBackgroundBlur ? "blur(4px)" : undefined,
      }}
    >
      {children}
    </div>
  );
};

export const GenericStartScreen = ({ hide = false, showButtons, ...rest }) => {
  if (hide) return <></>;

  return (
    <StartScreenOverlay {...rest}>
      {showButtons ? (
        <StartScreenButtons {...rest} />
      ) : (
        <span
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Waiting for host to start...
        </span>
      )}
    </StartScreenOverlay>
  );
};

export const CompleteStartScreen = ({ spectatorComponent, ...props } = {}) => {
  const multiplayer = Multiplayer();
  const playInstructions = useGlobalState("playInstructions");
  const startGame = useGlobalState("startGame");
  const isHost = multiplayer.isHost;
  const isSpectator = multiplayer.isSpectator;

  if (isSpectator) return <>{spectatorComponent || ""}</>;
  console.log("CompleteStartScreen");
  return (
    <GenericStartScreen
      hide={startGame}
      showButtons={isHost}
      multiplayer={multiplayer}
      textProps={{
        className: "banana-text",
        style: {
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
      onStart={() => {
        multiplayer.setState("playInstructions", false);
        multiplayer.setState("startGame", true);
      }}
    />
  );
};
