// import mixpanel from "mixpanel-browser";
import { init, identify, alias, track, people } from "mixpanel-browser";
let hostname = "https://ws.joinplayroom.com";
if (process.env?.REACT_APP_SERVER) {
  const hostparts = process.env.REACT_APP_SERVER.split("://");
  hostname = `${hostparts[0] === "wss" ? "https" : "http"}://${hostparts[1]}`;
}
init("2647310162474a4f7ca66a277c543032", {
  debug: false,
  api_host: `${hostname}/__mix` /*ip: 0*/,
});

let env_check = () =>
  process.env.NODE_ENV === "production" ||
  (typeof window !== "undefined" && window?._PLAYROOM_CONFIG?.sdkMode);
let actions = {
  identify: (id) => {
    try {
      if (env_check()) identify(id);
    } catch (e) {
      console.log(e);
    }
  },
  alias: (id) => {
    try {
      if (env_check()) alias(id);
    } catch (e) {
      console.log(e);
    }
  },
  track: (name, props) => {
    try {
      if (env_check()) track(name, props);
    } catch (e) {
      console.log(e);
    }
  },
  people: {
    set: (props) => {
      try {
        if (env_check()) people.set(props);
      } catch (e) {
        console.log(e);
      }
    },
  },
};

export default actions;
