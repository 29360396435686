// import { getFirestore } from "firebase/firestore";
// import { collection, addDoc, setDoc, doc } from "firebase/firestore";
// import Firebase from "./auth/firebase";
// import Auth from "./auth";
import Analytics from "../analytics";
import { getMyId, getMyPermanentId } from "./auth";
import Multiplayer from "./multiplayer";
import log from "./log";

// let db;
// let DB = () => {
//   const sdkMode = window?._PLAYROOM_CONFIG?.sdkMode;
//   if (typeof db !== "undefined") return db;
//   if (!sdkMode) {
//     db = getFirestore(Firebase());
//   }
//   db = false;
//   return db;
// };
const WEEKDAY = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// Types of logs that are sent to log db
export const LOGTYPES = {
  ROOMSTATE: "ROOMSTATE",
  GAMESTATE: "GAMESTATE",
  USERSTATE: "USERSTATE",
};

const LOGTYPETOFRIENDLY = {
  ROOMSTATE: "Room",
  GAMESTATE: "Game",
  USERSTATE: "User",
};

export async function addLog(data) {
  data.t = Date.now();
  data.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  data.localTime = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  data.weekday = WEEKDAY[new Date().getDay()];
  if (typeof window !== "undefined" && window?._PLAYROOM_CONFIG?.sdkMode) {
    data.sdkMode = true;
    data.sdkVersion = window?._PLAYROOM_CONFIG?.sdkVersion;
    try {
      Analytics.track(
        `${LOGTYPETOFRIENDLY[data.type]}: ${data.event || ""}`,
        data
      );
    } catch (e) {
      console.error(e);
    }
  }
  if (process.env.NODE_ENV !== "production") {
    return log("analytics:", data);
  }
  try {
    Analytics.track(
      `${LOGTYPETOFRIENDLY[data.type]}: ${data.event || ""}`,
      data
    );
    // await addDoc(collection(DB(), "logs"), data);
    // console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export async function addUserLog(eventName, data) {
  const multiplayer = Multiplayer();
  try {
    data = data || {};
    data.type = LOGTYPES.USERSTATE;
    data.event = eventName;
    data.userId = getMyId();
    data.permanentId = getMyPermanentId();
    if (multiplayer.currentRoom) {
      data.roomId = multiplayer.currentRoom;
    }
    if (multiplayer.getState("uid")) {
      data.roomUid = multiplayer.getState("uid");
    }
    if (multiplayer.isConnected) {
      data.role = multiplayer.isHost
        ? "host"
        : multiplayer.isSpectator
        ? "spectator"
        : "player";
      data.ping = multiplayer.getMyPlayerState()?.getState("p") || 0;
      data.webrtcConnected = multiplayer.getMyPlayerState().webrtcConnected;
    }
    return addLog(data);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

// export async function setUserProperties(data) {
//   if (process.env.NODE_ENV !== "production") return;
//   try {
//     const user = await Auth().getUser();
//     if (user) {
//       await setDoc(doc(DB(), "users", user.uid), data, { merge: true });
//     }
//   } catch (e) {
//     console.error("Error adding document: ", e);
//   }
// }
