import React from "react";
import { usePlayersList } from "../../hooks/multiplayer";
import AvatarIcon from "../AvatarIcon";
import "./style.css";

export default function AvatarIconStrip({
  centered,
  bottom,
  showNames,
  showScores,
  mobileMode,
  initialScore = 0,
  style = {},
}) {
  const players = usePlayersList(true);
  // console.log("pl", players);
  return (
    <div
      className={"player-avatar-strip" + (mobileMode ? " mobile" : "")}
      style={style}
    >
      {players.map((playerState) => {
        const playerScore =
          playerState.getState("profile")?.points ||
          playerState.getState("profile")?.score ||
          0;
        return (
          <div
            key={playerState.id}
            className="player-score-container"
            style={{ backgroundColor: playerState.getState("profile")?.color }}
          >
            <AvatarIcon playerState={playerState} />
            {showNames && <span>{playerState.getState("profile")?.name}</span>}
            {showScores && <span>{initialScore + playerScore}</span>}
          </div>
        );
      })}
    </div>
  );
}
