window.__TEMPSTORAGE__ = {};

function set(key, value) {
  if (window._USETEMPSTORAGE) {
    window.__TEMPSTORAGE__[key] = value;
  } else if (window._PLAYROOM_CONFIG?.sessionStorage) {
    sessionStorage.setItem(key, value);
  } else {
    localStorage.setItem(key, value);
  }
}

function get(key) {
  if (window._USETEMPSTORAGE) {
    return window.__TEMPSTORAGE__[key];
  } else if (window._PLAYROOM_CONFIG?.sessionStorage) {
    return sessionStorage.getItem(key);
  } else {
    return localStorage.getItem(key);
  }
}

const LocalStorageWrapper = {
  set,
  get,
};

export default LocalStorageWrapper;
