import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Game from "common/components/GameRenderer";
import { GameScene } from "./src/game";
import GamesMenu from "common/components/GamesMenu";
import BackgroundGame from "common/components/BackgroundGame";
import BackgroundRain from "common/components/BackgroundRain";
import BackgroundMusic from "common/modules/backgroundMusic";
import Multiplayer from "common/modules/multiplayer";
import { resetAllNonPlayroomStates } from "common/modules/multiplayer/state";
import TouchJoystick from "common/modules/controllers/touch";
import RoomCodeWithQR from "common/components/RoomCodeWithQR";
import MuteButton from "common/components/MuteButton";
import Leaderboard from "common/components/Leaderboard";
import ControllerHUD from "common/components/ControllerHUD";
import Toast from "common/components/Toast";

import imgBgController from "./img/grid.png";
import imgAvatar from "../../profile/img/avatar.png";

import soundBgMusic from "common/sfx/theme.mp3";

// import RateGame from "common/components/RateGame";

import PlaylistThumbnailContainer from "common/components/PlaylistThumbnailContainer";

import "./style.css";
import { useGlobalState } from "common/hooks/multiplayer";

import { getPlaylistFromId } from "pages/games/playlists";
import AvatarIconOnly from "common/components/AvatarIconOnly";

function randRange(minNum, maxNum) {
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}

function bot(controller) {
  const waitFor = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const waitForRandom = (min, max) => waitFor(randRange(min, max));
  const interval = setInterval(async () => {
    controller.emit("keydown", "up");
    await waitForRandom(100, 300);
    controller.emit("keydown", "left");
    await waitForRandom(100, 300);
    controller.emit("keyup", "up");
    controller.emit("keydown", "down");
    controller.emit("keyup", "left");
    await waitForRandom(100, 300);
    controller.emit("keyup", "down");
    await waitForRandom(100, 1000);
    controller.emit("keydown", "right");
    await waitForRandom(100, 300);
    controller.emit("keyup", "right");
    await waitForRandom(100, 300);
    controller.emit("keydown", "down");
    controller.emit("keyup", "left");
    await waitForRandom(100, 300);
    controller.emit("keyup", "down");
  }, 2000);

  controller.on("destroy", () => clearInterval(interval));
}

export default function GamePage() {
  const multiplayer = Multiplayer();
  const players = multiplayer.getPlayers();
  const [showLobby, setShowLobby] = useState(false);

  const location = useLocation();
  const stayAtController =
    location && location.state && location.state.stayAtController;

  useEffect(() => {
    if (multiplayer.isRenderServer()) {
      multiplayer.connection.setAutoAdmitPlayers(true);

      BackgroundMusic("game").stop();
      setTimeout(() => {
        // ---- Reset Sounds to Playroom-only sounds
        // reset BackgroundMusic
        if (BackgroundMusic().getCurrentlyPlaying() !== soundBgMusic) {
          BackgroundMusic("game").stop();
          BackgroundMusic().play(soundBgMusic);
          BackgroundMusic().setVolume(1);
        }
      }, 2000);
    }
  }, []);
  useEffect(() => {
    var controller = null;
    // setTimeout(() => {
    //   setShowLobby(true);
    // }, 3000);
    if (!multiplayer.isSpectator) {
      // controller = new TouchJoystick({
      //   degree: true,
      //   className: "lobby",
      //   labels: { dpad: "move", b1: "push" },
      // });
      // multiplayer.attachController(controller);
      // if (window.ISBOT) bot(controller);
    } else {
      BackgroundMusic().setVolume(0.6, true);
    }

    return () => {
      if (!multiplayer.isSpectator) {
        multiplayer.detachControllerLegacy();
        if (controller) controller.destroy();
        controller = null;
      }
    };
  });

  useEffect(() => {
    resetAllNonPlayroomStates(multiplayer);
    // BackgroundMusic("game").stop();
    // BackgroundMusic().stop();
    // BackgroundMusic().play(soundBgMusic);
    // BackgroundMusic().setVolume(1);
  }, [players]);

  const myColor = !multiplayer.isSpectator
    ? multiplayer.getMyPlayerState()?.getState("profile").color
    : "white";

  const playlist = useGlobalState("playlist");
  const playlistThumbnail = (
    <PlaylistThumbnailContainer
      playlist={getPlaylistFromId(playlist)}
      playlistContainerStyles={{
        width: "100%",
      }}
    />
  );

  const playerBreadcrumbs = !multiplayer.isHost
    ? ["Waiting for host to start"]
    : [
        "You are the host",
        <div
          key={playlist + "1"}
          style={{
            width: "6rem",
            marginBottom: "8px",
            animation: "slide-right 0.5s 0.2s",
          }}
        >
          {playlistThumbnail}
        </div>,
      ];

  // useEffect(()=>{
  //   if (playlist) setPlaylistThumbnail(<PlaylistThumbnailContainer playlist={getPlaylistFromId(playlist)} />)
  // }, [playlist]);

  return (
    <>
      <GamesMenu
        key="gamesmenu"
        showStartGamesButtonIfHost={true}
        showIfHost={stayAtController ? false : true}
        disableNavigation="Tap Play above to start"
        onStartGames={() => {
          multiplayer.navigate("/autoplaygame", { firstTime: true });
        }}
      />

      {multiplayer.isSpectator ? (
        <div className={"lobby-container" + " show-lobby"}>
          <RoomCodeWithQR />
          <MuteButton castMode />
          <Leaderboard showPing showHost showScore />
          <Toast flag bottom right visible>
            {/* Uncomment - For hot reloading the lobby */}
            {/* <a onClick={()=>{setShowLobby(false); setTimeout(()=> setShowLobby(true), 1000)}}>Here</a> */}
            <span className="toast-span">Host selecting game</span>
          </Toast>
          {playlist ? (
            // <Toast bottom right visible={showLobby}>
            <div
              // Add key to retrigger animation: https://stackoverflow.com/questions/63186710/how-to-trigger-a-css-animation-on-every-time-a-react-component-re-renders
              key={playlist + "1"}
              style={{
                position: "absolute",
                bottom: "11rem",
                right: "3rem",
                zIndex: 999,
                width: "18rem",
                animation: "slide-left 0.5s",
              }}
            >
              {playlistThumbnail}
            </div>
          ) : (
            // </Toast>
            <></>
          )}
          {/* <div className="join-instructions-tv">
            <p className="instruction-heading">Room Starting...</p>
          </div> */}
          {/* <BackgroundGame
            style={{
              opacity: showLobby ? 1 : 0,
              transition: "opacity 2s",
              background:
                `linear-gradient(0deg, rgb(106, 62, 236) 50%, rgb(248, 160, 242) 50%)`,
            }}
          ></BackgroundGame> */}
          <BackgroundRain key="rain" numDrops={50} />
          <BackgroundGame
            style={{
              opacity: 1,
              transition: "opacity 2s",
              background: `radial-gradient(82.62% 102.34% at 50% 91.54%, #5018F5 34.9%, #F8A0F2 100%)`,
            }}
          ></BackgroundGame>
          {/* <BackgroundGame
            style={{
              opacity: 1,
              transition: "opacity 2s",
              background: ` radial-gradient(82.62% 102.34% at 50% 91.54%, #5018F5 34.9%, #F8A0F2 100%);`,
            }}
          ></BackgroundGame> */}
          {showLobby && (
            <Game
              key="game"
              canvasStyle={{ zIndex: 3 }}
              scenes={[GameScene]}
              physics={{
                default: "matter",
                matter: {
                  // debug: true,
                  gravity: { y: 0 },
                },
              }}
            />
          )}
        </div>
      ) : (
        <>
          <ControllerHUD
            breadcrumbs={playerBreadcrumbs}
            backgroundStyle={{
              background: `linear-gradient(180deg, rgba(255,255,255,0) 0%, ${myColor} 100%), url(${imgBgController}), ${myColor}`,
            }}
            avatarStyle={{
              display: "none",
              background: `linear-gradient(180deg, #FFDA93 0%, #FF9680 100%)`,
            }}
            // avatarUrl={imgAvatar}
            showRoomCode={!multiplayer.isHost}
          />
          {/* <RateGame /> */}
        </>
      )}
    </>
  );
}
