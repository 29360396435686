import React, { useEffect } from "react";
import QRCode from "react-qr-code";
import { Portal } from "react-portal";
import Multiplayer from "../../modules/multiplayer";
import "./style.css";

export default function QRCodePortal({ zIndex, demoMode }) {
  return (
    <Portal>
      <RoomQRCode zIndex={zIndex} demoMode={demoMode} />
    </Portal>
  );
}

export function RoomQRCode({
  zIndex,
  demoMode,
  noAbsolute,
  noText,
  size = 256,
  containerStyles = {},
  baseUrl = "https://joinplayroom.com/",
}) {
  const multiplayer = Multiplayer();
  const roomCode = multiplayer.currentRoom;
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  return (
    <div
      className="room-code-sticker"
      style={{
        ...{
          zIndex: zIndex || "initial",
          position: noAbsolute ? "initial" : "absolute",
        },
        ...containerStyles,
      }}
    >
      <div className="qr-code">
        {/*
          References:
          - Responsive QR, from the demo of react-qr-code: https://github.com/rosskhanas/react-qr-code/blob/master/demo/src/components/App.js
          */}

        <QRCode
          id="QRCodeScaled"
          size={size}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={
            demoMode
              ? "https://joinplayroom.com/demohost"
              : `${baseUrl}R${roomCode ? roomCode : ""}`
          }
          viewBox={`0 0 ${size} ${size}`}
        />
      </div>
      {!noText && roomCode && (
        <div className="room-code">
          <b>{roomCode}</b>
          <span>Scan QR code to join the room</span>
        </div>
      )}
      {!noText && !roomCode && (
        <div className="room-code">
          <b>Scan to start</b>
          <span>Download the game from the Appstore</span>
        </div>
      )}
    </div>
  );
}
