import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Portal } from "react-portal";
import setIntervalX from "../../utils/setIntervalX";

import "./style.css";

export const Countdown = ({
  onCountdownChange = () => {},
  onFinish = () => {},
  startingFrom = 3,
  finalText = "GO!",
}) => {
  const [currentNumber, setCurrentNumber] = useState("");

  useEffect(() => {
    // setCurrentNumber(startingFrom);
    setIntervalX(
      (x, intervalId) => {
        setCurrentNumber(startingFrom - x);

        onCountdownChange && onCountdownChange(startingFrom);
      },
      1000,
      startingFrom,
      (x, intervalId) => {
        setCurrentNumber(finalText);

        if (onFinish || onCountdownChange)
          setTimeout(() => {
            console.log("[debug] i'm here:", onFinish, onCountdownChange)
            onCountdownChange(0);
            onFinish(x, intervalId);
          }, 1000);
      }
    );
  // eslint-disable-next0line
  }, []);

  return (
    <div className={"game-start-countdown"} key={"countdown-" + currentNumber}>
      {currentNumber}
    </div>
  );
};

export const FullscreenCountdown = ({containerStyles, ...props}) => {
  return (
    <div className={"fullscreen-game-start-countdown"} style={containerStyles}>
      <Countdown {...props} />
    </div>
  );
};

export const FullscreenCountdownAsync = forwardRef((props, ref) => {
  const [countdownCallback, setCountdownCallback] = useState(null);
  useImperativeHandle(ref, () => ({
    start: () => {
      return new Promise((resolve, reject) => {
        setCountdownCallback(() => () => {
          setCountdownCallback(null);
          resolve();
        });
      });
    },
  }));
  if (!countdownCallback) return null;
  return (
    <Portal>
      <div className={"fullscreen-game-start-countdown"}>
        <Countdown {...props} onFinish={countdownCallback} />
      </div>
    </Portal>
  );
});

export default Countdown;
