import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useEffect } from "react";
import { usePlayersList } from "../../hooks/multiplayer";
import { useState } from "react";

// given player count and screen width, calculate player's width and player x (starting from center of screen) with gap in between such that they all fit in screen width
function calculatePlayerSizeAndPosition(
  playerCount,
  index,
  screenWidth,
  playerCurrentWidth,
  gap = 50,
  emptySpaceOnEachSide = 100
) {
  const availableWidth = screenWidth - emptySpaceOnEachSide * 2;
  const totalWidth = playerCount * playerCurrentWidth + (playerCount - 1) * gap;
  if (totalWidth > availableWidth) {
    // scale down player's width
    const playerScale = availableWidth / totalWidth;
    const playerWidth = playerScale * playerCurrentWidth;
    const newTotalWidth =
      playerCount * playerWidth + (playerCount - 1) * (gap * playerScale);
    const playerPosX =
      emptySpaceOnEachSide +
      availableWidth / 2 -
      newTotalWidth / 2 +
      index * (playerWidth + gap * playerScale) +
      playerWidth / 2;
    return { playerScale, playerPosX };
  }
  // base case. No need to scale down, just calculate x
  const playerPosX =
    emptySpaceOnEachSide +
    availableWidth / 2 -
    totalWidth / 2 +
    index * (playerCurrentWidth + gap);
  return { playerScale: 1, playerPosX };
}

export default function PlayersAutofitHorizontal({
  playerElement: PlayerElement,
  playerWidth,
  gap = 0,
  // additionalPlayers = [],
}) {
  const dimensions = useWindowDimensions();
  const players = usePlayersList();
  // const players = _players.concat(additionalPlayers);
  const [playerSizes, setPlayerSizes] = useState([]);
  useEffect(() => {
    // TODO: fix this reference to scene DOM somehow
    const sceneWidth =
      document.querySelector(".scene-renderer .scene-renderer-content")
        ?.clientWidth || dimensions.width;
    console.log(
      "scenewidth",
      sceneWidth,
      document.querySelector(".scene-renderer .scene-renderer-content")
        ?.clientWidth
    );
    const newPlayerSizes = players.map((_player, index) =>
      calculatePlayerSizeAndPosition(
        players.length,
        index,
        sceneWidth,
        playerWidth,
        gap,
        0
      )
    );
    setPlayerSizes(newPlayerSizes);
  }, [dimensions, players, playerWidth]);

  return (
    <div
      className="players-autofit-horizontal"
      style={{ position: "relative" }}
    >
      {players.map((player, index) => {
        const size = playerSizes[index];
        if (!size) return false;
        return (
          <div
            className="player-fitted"
            style={{
              position: "absolute",
              top: 0,
              left: size.playerPosX,
              transform: `scale(${size.playerScale})`,
              transformOrigin: "0 0",
              width: playerWidth,
            }}
          >
            <PlayerElement playerState={player} index={index}></PlayerElement>
          </div>
        );
      })}
    </div>
  );
}
