import React, { useEffect, useState, useRef } from "react";
import GamesMenu from "common/components/GamesMenu";
import BackgroundGame from "common/components/BackgroundGame";
import BackgroundMusic from "common/modules/backgroundMusic";
import TwoButtonJoystick from "common/modules/controllers/twobuttons";
import ControllerHUD from "common/components/ControllerHUD";
import Multiplayer from "common/modules/multiplayer";
import imgRocket from "./img/rocket.png";
import sfxLaunch from "./sfx/launch.mp3";
import UIfx from "common/modules/uifx";
import {
  usePlayersList,
  useGlobalState,
  useGlobalRoundState,
  usePlayerState,
} from "common/hooks/multiplayer";
import PlayerRocket from "./rocket";
import bgTimerSound from "common/sfx/timer.mp3";
import "./style.css";
import "./launch-button.scss";

import SmokeLayer from "./components/SmokeLayer";

import {
  StartScreenOverlay,
  StartScreenButtons,
} from "common/components/StartScreen";

import { FullscreenCountdown } from "common/components/Countdown";

const FUEL_PER_PUSH = 5;

function randRange(minNum, maxNum) {
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}

function bot(controller) {
  const interval = setInterval(async () => {
    controller.emit("keydown", "b1");
  }, randRange(1000, 2000));

  controller.on("destroy", () => clearInterval(interval));
}

function useForceUpdate() {
  // eslint-disable-next-line
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

function resetGame() {
  const multiplayer = Multiplayer();
  multiplayer.setRoundState("live");
  multiplayer.setRoundState("winner");
  // multiplayer.setRoundState("startGame", undefined);
  const players = multiplayer.getPlayers();
  Object.keys(players).forEach((playerId) => {
    const playerState = players[playerId];
    playerState.setState("fuel");
    playerState.setState("done");
  });
}

function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export default function GamePage({ gameInfo, endRound }) {
  const multiplayer = Multiplayer();

  const playInstructions = useGlobalState("playInstructions");
  const playInstructionsRef = useRef(null);

  const forceUpdate = useForceUpdate();
  const myState = multiplayer.getMyPlayerState();
  const myColor = myState && myState.getState("profile")?.color;
  const myFuel = myState && (myState.getState("fuel") || 0);
  const players = usePlayersList();
  // const [lastPump, setLastPump] = useState(0);
  const [isMidGame, setIsMidGame] = useState(false);
  const [countdown, setCountdown] = useState(false);
  const winner = useGlobalRoundState("winner");
  const winnersSoFar = useGlobalRoundState("winnersSoFar", []);
  const gameIsLive = useGlobalRoundState("live");
  const newGameRequested = useGlobalRoundState("newGame");
  const startGame = useGlobalRoundState("startGame");

  // ----- How the breakpoints work:

  // {

  //   // anything more takes on the last value

  //   // < players.length : limitingFactor
  //   3: 2,
  //   2: 1,
  //   // anything less is not accepted at all
  //  }

  function determineWin(
    winnersSoFar,
    players,
    scoringArray,
    breakpoints,
    setState = () => {},
    forceEval = false
  ) {
    // check for force draw
    if (winnersSoFar === -1 || winnersSoFar.includes(-1)) {
      setState(-1);
      return -1;
    }

    console.log("winnersSoFar in determineWin:", winnersSoFar);

    if (winnersSoFar.length && players.length) {
      var limitingFactor = -1;
      var _sortedBreakpointKeys = Object.keys(breakpoints).sort();

      _sortedBreakpointKeys.forEach((key) => {
        if (players.length >= key) {
          limitingFactor = breakpoints[key];
        }
      });

      console.log("limitingFactor before:", limitingFactor);

      // incase the winnersSoFar are less than the minimum break, it should break the entire
      // function except when forceEval is true. (for example, it could become true if the timer
      // has ended)
      if (limitingFactor === -1 || winnersSoFar.length < limitingFactor) {
        if (!forceEval) {
          return false;
        } else {
          // get the minimum value,
          limitingFactor = breakpoints[_sortedBreakpointKeys[0]];
        }
      }

      console.log(
        "winnersSoFar.length, scoringArray.length, limitingFactor, after:",
        winnersSoFar.length,
        scoringArray.length,
        limitingFactor
      );

      let winnersArray = [];
      //            one of these three conditions will become the limiting factor
      for (
        let i = 0;
        i < winnersSoFar.length &&
        i < scoringArray.length &&
        i < limitingFactor;
        i++
      ) {
        winnersArray.push({
          id: winnersSoFar[i],
          score: scoringArray[i],
        });
      }

      setState(winnersArray);

      return winnersArray;
    }
    return false;
  }

  const _tempWinnerSoFar = useGlobalRoundState("_tempWinnerSoFar");

  useEffect(() => {
    if (_tempWinnerSoFar && !winnersSoFar.includes(_tempWinnerSoFar)) {
      console.log("winnersSoFar in _tempWinnerSoFar:", winnersSoFar);
      multiplayer.setRoundState(
        "winnersSoFar",
        winnersSoFar ? [...winnersSoFar, _tempWinnerSoFar] : [_tempWinnerSoFar]
      );
    }
  }, [_tempWinnerSoFar]);

  useEffect(() => {
    if (multiplayer.isSpectator && winnersSoFar?.length) {
      determineWin(
        winnersSoFar,
        players,
        [100, 75, 50],
        {
          4: 3,
          3: 2,
          2: 1,
        },
        (winners) => {
          console.log("YAY! Game ended. Winners are:", winners);

          multiplayer.setRoundState("winner", winners);
          BackgroundMusic("timer").stop();

          // Ends the round!
          endRound(winners);
        }
      );
    }
  }, [winnersSoFar]);

  const updateWinners = (playerId, changedKey, playerState, playerObj) => {
    UIfx(sfxLaunch).play(0.7);
    console.log("winnersSoFar in usePlayerState:", winnersSoFar);
    multiplayer.setRoundState("_tempWinnerSoFar", playerId);
  };

  // useEffect(() => {
  usePlayerState((playerId, changedKey, playerState, playerObj) => {
    forceUpdate();
    if (multiplayer.isSpectator) {
      if (playerState["fuel"] >= 100 && !playerState["done"]) {
        if (!winner) {
          updateWinners(playerId, changedKey, playerState, playerObj);
        }
        playerObj.setState("done", true);
        forceUpdate();
      }
    }
  });
  // }, [winnersSoFar])

  const stopCountdown = () => {
    console.log("Stopping countdown");
    multiplayer.setRoundState("live", true);
    setCountdown(false);
    setIsMidGame(false);
    BackgroundMusic("timer").play(bgTimerSound);
  };

  // reset game state if host requests it (and the first time)
  useEffect(() => {
    if (multiplayer.isSpectator) {
      resetGame();
    }
    return () => {
      if (multiplayer.isSpectator) {
        BackgroundMusic("timer").stop();
      }
      //   if (!multiplayer.isSpectator) {
      //     multiplayer.detachControllerLegacy();
      //     if (controller) controller.destroy();
      //     controller = null;
      //   }
    };
    // eslint-disable-next-line
  }, [newGameRequested]);

  useEffect(() => {
    var controller = null;
    if (startGame) {
      if (multiplayer.isSpectator) {
        setCountdown(true);
      }
      if (!multiplayer.isSpectator) {
        controller = new TwoButtonJoystick({
          onebutton: true,
          className: "rocketfuel-launch-button",
          labels: { b1: "<span>tap here to fuel up</span>" },
        });
        if (window.ISBOT) bot(controller);
        var lastPump = 0;
        controller.on("keydown", (key) => {
          const gameIsLive = multiplayer.getRoundState("live");
          console.log(key, gameIsLive, lastPump, lastPump + 250 <= Date.now());
          if (!winner && gameIsLive && lastPump + 250 <= Date.now()) {
            // setLastPump(Date.now());
            lastPump = Date.now();
            var lastFuel = myState.getState("fuel") || 0;
            if (lastFuel < 100) {
              myState.setState(
                "fuel",
                Math.min(lastFuel + FUEL_PER_PUSH, 100),
                true
              );
            }
          }
        });
      }

      // multiplayer.attachController(controller);
      return () => {
        if (multiplayer.isSpectator) {
          BackgroundMusic("timer").stop();
        }
        if (startGame && !multiplayer.isSpectator) {
          multiplayer.detachControllerLegacy();
          if (controller) controller.destroy();
          controller = null;
        }
      };
    }
  }, [startGame]);

  if (!isMidGame) {
    players.forEach((playerState) => {
      if (playerState.getState("fuel") > 50) {
        setIsMidGame(true);
      }
    });
  }
  return (
    <>
      {/* mid-game sky */}
      <BackgroundGame
        style={{
          background:
            "linear-gradient(180deg, #FFB0CC 49.66%, #76A7B7 104.47%, #6BFFB8 157.42%)",
        }}
      ></BackgroundGame>
      {/* 
      
      <BackgroundGame
        style={{ opacity: winner?1:0, transition: "opacity 3s", background: "linear-gradient(180deg, #8D6BED 49.66%, #76A7B7 104.47%, #6BFFB8 157.42%)" }}
      ></BackgroundGame> */}

      {/* end-game sky 2 aka space! */}
      <BackgroundGame
        style={{
          opacity: winner ? 1 : 0,
          transition: "opacity 5s",
          background:
            // "linear-gradient(180deg, #FFB0CC 49.66%, #76A7B7 104.47%, #6BFFB8 157.42%)",
            "linear-gradient(180.27deg, #0D0425 16.85%, #34109F 120.37%, #6BDCFF 140.73%, #6BFFB8 158.06%)",
        }}
      >
        <div className="star topleft" />
        <div className="star topright" />
        <div className="star bottomleft" />
        <div className="star bottomright" />
      </BackgroundGame>
      <GamesMenu
        key="gamesmenu"
        gradient
        hideRoomCode
        gameInfo={gameInfo}
        // showRestart={winner && multiplayer.isHost}
        // onRestart={async () => {
        //   multiplayer.setRoundState("newGame", Math.random());
        // }}
      />

      {multiplayer.isSpectator ? ( // tv/laptop screen
        <>
          {countdown ? (
            <FullscreenCountdown
              key={gameIsLive || "fullscreen-countdown"}
              startingFrom={4}
              textOverrides={["Ready"]}
              onCountdownChange={setCountdown}
              onFinish={stopCountdown}
              countdownContainerStyles={{
                fontFamily: "Lilita One",
                fontWeight: 400,
                textTransform: "none",
              }}
            />
          ) : (
            <></>
          )}

          <div
            className="rocket-countdown-container banana-text"
            style={{
              opacity: 1, //override global
            }}
          >
            {!countdown && !winner ? (
              isMidGame ? (
                <p
                  style={{
                    fontSize: "inherit",
                    animation: `1.6s shake-y 0.3s infinite cubic-bezier(.36,.07,.19,.97) both`,
                  }}
                >
                  Tap!
                </p>
              ) : (
                <>
                  Tap to pump
                  <br />
                  the fuel
                </>
              )
            ) : (
              ""
            )}
          </div>
          <Rockets
            players={players}
            winner={winner}
            winnersSoFar={winnersSoFar}
            isMidGame={isMidGame}
          />
        </>
      ) : (
        <ControllerHUD
          breadcrumbs={["Rocket Fuel"]}
          backgroundStyle={{
            background:
              // `linear-gradient(180.27deg, #4622AD 16.85%, #8D6CEE 120.37%, #6BDCFF 140.73%, #6BFFB8 158.06%)`,
              "linear-gradient(180deg, #FFB0CC 49.66%, #76A7B7 104.47%, #6BFFB8 157.42%)",
          }}
          avatarStyle={{
            background: `linear-gradient(180deg, #8D6BED 49.66%, #76A7B7 104.47%, #6BFFB8 157.42%)`,
          }}
          avatarUrl={imgRocket}
        >
          {/* <div className="rocketfuel-launch-button disabled">
            <span>tap here to fuel up</span>
          </div> */}

          {!startGame && !gameIsLive ? (
            <StartScreenOverlay isBackgroundBlur={true}>
              {multiplayer.isHost ? (
                <StartScreenButtons
                  // onPlayInstructions={() => {
                  //     multiplayer.setState("playInstructions", !playInstructions);
                  // }}

                  playInstructionsRef={playInstructionsRef}
                  playInstructions={playInstructions}
                  startText={"Start!"}
                  onStart={() => {
                    multiplayer.setRoundState("playInstructions", false);
                    multiplayer.setRoundState("startGame", true, true);
                  }}
                />
              ) : (
                <span
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="banana-text"
                >
                  <span style={{ fontSize: "3.5rem" }}>Get ready!</span>
                  <span style={{ fontSize: "2rem" }}>
                    Waiting on host to start...
                  </span>
                </span>
              )}
            </StartScreenOverlay>
          ) : (
            <div className="controller-progressbar">
              <div className="finish-line" />
              <div
                className="bar"
                style={{
                  height: `calc(${myFuel}% - 0.6rem)`,
                  backgroundColor: myColor,
                }}
              ></div>
            </div>
          )}
        </ControllerHUD>
      )}
    </>
  );
}

function Rockets({ players, winner, winnersSoFar, isMidGame }) {
  // console.log("winner in rocket", winner);
  return (
    <div className={"rockets-container " + (winner ? "won" : "")}>
      <SmokeLayer />

      <div className="rockets">
        {players.map((playerState, idx) => {
          const profile = playerState.getState("profile");

          // console.log("winnersSoFar, Array.isArray(winnersSoFar)", winnersSoFar, Array.isArray(winnersSoFar))
          const isWinner = winnersSoFar
            ? Array.isArray(winnersSoFar)
              ? winnersSoFar.includes(playerState.id)
              : winnersSoFar === playerState.id
            : false;

          return (
            <PlayerRocket
              playerName={profile.name}
              fuelPercent={playerState.getState("fuel") || 0}
              color={profile?.color || "red"}
              playerState={playerState}
              isDone={playerState.getState("done")}
              hasWon={isWinner}
              avatarContainerStyles={{
                // https://stackoverflow.com/questions/15962710/stop-animation-and-start-transition-on-hover
                animation: isWinner
                  ? "launch-animation 2s ease-in forwards"
                  : isMidGame && playerState.getState("fuel") > 50
                  ? `0.42s shake ${
                      idx * 0.56
                    }s infinite cubic-bezier(.36,.07,.19,.97) both`
                  : 0,
                transition: isWinner ? `transform 2s ease-in` : undefined,
                // transform: isWinner ? "translateY(-100vh)" : undefined,
              }}
              showFire={isMidGame && playerState.getState("fuel") > 50}
            />
          );
        })}
      </div>
    </div>
  );
}
