import BrowserStorage from "./localStorageWrapper";
import URLHash from "./urlhash";

function isPhoneViewOpenedInBrowser() {
  const preview = URLHash.getUrlHashParameter("preview");
  return preview === "phone";
}

function sendMessage(msg) {
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.NativeMethods
  ) {
    window.webkit.messageHandlers.NativeMethods.postMessage(msg);
  }
}

function loadURL(url) {
  sendMessage({ name: "loadURL", url });
}

function setStandbyURL(url) {
  sendMessage({ name: "setStandbyURL", url });
}
var currentRotation = "portrait";
function rotateLandscape() {
  sendMessage({ name: "rotate", direction: "landscape" });
  currentRotation = "landscape";
}

function rotatePortait() {
  sendMessage({ name: "rotate", direction: "portrait" });
  currentRotation = "portrait";
}

function getCurrentRotation() {
  return currentRotation;
}

function showAirplay() {
  sendMessage({ name: "showAirplay" });
}

function changeBootURL() {
  sendMessage({ name: "changeBootURL" });
}

async function getInvocationURL() {
  if (!isNative()) return "";
  return new Promise((resolve, reject) => {
    window.getInvocationURLCallback = function (url) {
      resolve(url);
    };
    sendMessage({ name: "getInvocationURL" });
  });
}

function disableShake() {
  sendMessage({ name: "disableShake" });
}

function haptic(type) {
  sendMessage({ name: "haptic", type: type });
}

// function vibrate(duration, intensity = 1, sharpness = 1){
//   sendMessage({ name: "haptic", type: "vibration", duration, intensity, sharpness });
// }

function isApp() {
  // check if native methods exist. This means we are in app mode
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.NativeMethods
  ) {
    return true;
  }
}

function isNative() {
  if (isApp()) {
    return true;
  }

  // check if dev override is enabled
  if (
    isPhoneViewOpenedInBrowser() ||
    (localStorage && BrowserStorage.get("DEV"))
  ) {
    return true;
  }

  // probably a web visitor
  return false;
}

export function isController() {
  if (isNative()) {
    return true;
  }

  if (isAndroid) return true;

  if (isIOS) return true;
  return false;
}

var isAndroid = /(android)/i.test(navigator.userAgent);

var isIOS = (function () {
  var iosQuirkPresent = function () {
    var audio = new Audio();

    audio.volume = 0.5;
    return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
  };

  var isIOS = /iPhone|iPod/.test(navigator.userAgent);
  var isAppleDevice = navigator.userAgent.includes("Macintosh");
  var isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
})();

async function getPlatformInfo() {
  if (!isNative()) return { platform: "web" };
  return new Promise((resolve, reject) => {
    window.getPlatformInfoCallback = function (url) {
      resolve(url);
    };
    sendMessage({ name: "getPlatformInfo" });
  });
}

const NativeMethods = {
  rotateLandscape,
  rotatePortait,
  getCurrentRotation,
  loadURL,
  setStandbyURL,
  showAirplay,
  haptic,
  isApp,
  isNative,
  changeBootURL,
  getInvocationURL,
  getPlatformInfo,
  // vibrate,
  disableShake,
  sendMessage,
  isController,
};
export default NativeMethods;
