import { useState, useEffect } from "react";
import { useOtherPlayerState } from "../hooks/multiplayer";
import Multiplayer from "../modules/multiplayer";
import AvatarCreator from "../modules/avatarCreator";
export default function PlayerColoredImage(props) {
  const multiplayer = Multiplayer();
  const [avatarDataUrl, setAvatarDataUrl] = useState(null);
  const profile = useOtherPlayerState(
    props.playerState || multiplayer.getMyPlayerState(),
    "profile"
  );
  useEffect(() => {
    if (props.src && profile?.color) {
      const av = new AvatarCreator(props.src);
      av.fillColor(profile.color).then(() => {
        setAvatarDataUrl(av.toDataURL());
      });
    } else if (multiplayer.isSpectator) {
      setAvatarDataUrl(props.src);
    }
    // eslint-disable-next-line
  }, [props.src, profile]);

  return (
    <img
      className={props.className}
      style={props.style}
      width={props.width}
      src={avatarDataUrl}
    />
  );
}
