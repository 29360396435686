import "./style.scss";

const CircularConfetti = ({ children, animate = false }) => {
  return (
    <div className={`subContainer ${animate ? "startConfetti" : ""}`}>
      {/* <span className="background"></span> */}
      {/* <span className="icon">🙌</span> */}
      <span className="square"></span>
      <span className="circle"></span>
      <span className="triangle"></span>
      {children}
    </div>
  );
};

export default CircularConfetti;
