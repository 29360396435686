import { MultiplayerConstants } from "./constants";
import SuperEventEmitter from "../superEventEmitter";
import Peer from "simple-peer";
import log from "../log";
// import {Multiplayer} from "../"

// polyfill process.nextTick
window.process = {
  ...window.process,
  nextTick: function (cb, arg1, arg2, arg3) {
    setTimeout(() => {
      cb(arg1, arg2, arg3);
    }, 0);
  },
};
window.process.env = window.process.env || {};

function hexToRgb(hex) {
  // Remove hash if there is one
  hex = hex.replace(/#/g, "");
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        hexString: `#${hex}`,
        hex:
          (parseInt(result[1], 16) << 16) |
          (parseInt(result[2], 16) << 8) |
          parseInt(result[3], 16),
      }
    : null;
}

export default class PlayerState extends SuperEventEmitter {
  constructor({
    websocketSend,
    id,
    myId,
    isRenderServer,
    isRenderServerOrHostIfNotCasting,
    playerIsSpectator,
    playerIsProxy,
    broadcastUnreliable,
    setPlayerState,
    getBootDate,
    avatarList,
  }) {
    super();
    this.websocketSend = websocketSend;
    this.broadcastUnreliable = broadcastUnreliable;
    this.setPlayerState = setPlayerState;
    this.myId = myId;
    this.id = id;
    this.avatarList = avatarList;
    this.syncIntervalTime = MultiplayerConstants.SyncIntervalTime;
    // this.isHost = isHost;
    this.isRenderServer = isRenderServer;
    this.isRenderServerOrHostIfNotCasting = isRenderServerOrHostIfNotCasting;
    this.playerIsSpectator = playerIsSpectator;
    this.playerIsProxy = playerIsProxy;
    this.state = {};
    this.iUpdatedStateAt = {};

    if (this.playerIsProxy) {
      this.state.__proxyBy = myId;
    }

    this.getBootDate = getBootDate;
    this.stateKeyUpdateOrder = {}; // holds the key:date of last update of that value, 'date' is server's date
    this.inputState = {};
    this.peer = null;
    this.webrtcConnected = false;
    this.webrtcRetryCount = 0;
    this.controllerLegacy = false;
    this.heartbeatInterval = 0;
    this.retryWebRtcTimeout = 0;
    this.isDestroyed = false;
    this.timeDiffFromServer = 0;
    this.bot = undefined;
    // this.multiplayer = Multiplayer();
    // this.startWebrtc();

    // non-host players (except spectators) will send their input to server
    this.on("input_broadcast", (data) => {
      if (!isRenderServerOrHostIfNotCasting() && !playerIsSpectator) {
        this.send({ pinput: data });
      }
    });
  }

  send(data, reliable, skipUnreliableIfHost) {
    // try sending via webrtc (unreliable but fast)
    if (this.webrtcConnected && !reliable && !this.playerIsProxy) {
      try {
        log(
          "sending unreliable",
          data,
          reliable,
          "webrtcConnected",
          this.webrtcConnected
        );
        this.peer.send(JSON.stringify(data));
      } catch (e) {
        log(e);
      }
    } else if (
      !reliable &&
      this.isRenderServerOrHostIfNotCasting() &&
      !skipUnreliableIfHost
    ) {
      this.broadcastUnreliable(data);
    }
    // else send via websocket (reliable but slow)
    else if (!this.playerIsProxy) {
      if (this.isRenderServerOrHostIfNotCasting()) data.for = this.id;
      log(
        "sending reliable",
        data,
        reliable,
        "webrtcConnected",
        this.webrtcConnected
      );
      this.websocketSend(JSON.stringify(data));
    }
  }

  startWebrtc() {
    if (this.retryWebRtcTimeout) {
      clearTimeout(this.retryWebRtcTimeout);
      this.retryWebRtcTimeout = 0;
    }
    if (this.peer) {
      this.peer.destroy();
    }
    // log(this._idToHuman(), "::webrtc:", "connecting...", this.isRenderServer(), this.myId === this.id);
    // if we are not render server and this state is for ourselves
    if (!this.isRenderServerOrHostIfNotCasting() && this.myId === this.id) {
      log("webrtc::connecting", "not render server, isMyId");
      this.peer = new Peer({ initiator: true, objectMode: true });
      this.peer.on("signal", (data) => {
        // send signal to render server.
        log("got signal", data);
        this.send({ signal: data }, true);
      });

      this.peer.on("connect", () => {
        this.webrtcConnected = true;
        this.webrtcRetryCount = 0;
        this.emit("webrtc_connected");
        clearTimeout(this.retryWebRtcTimeout);
      });

      this.peer.on("data", (data) => {
        data = JSON.parse(data);
        log("webrtc::host says:", data);
        if (data.ping) {
          // keep approx. diff between server time and client time
          this.timeDiffFromServer = data.ping - Date.now();
          this.send({ pong: data.ping }); // send time back so they can calculate rtt
        } else if (data.pstate) {
          // a state change for some player has arrived
          this.setPlayerState(data);
        } else if (data.gstate) {
          this.emit("global_state", data.gstate);
        }
      });

      this.peer.on("stream", (stream) => {
        this.emit("stream", stream);
      });

      this.peer.on("close", () => {
        log(this._idToHuman(), "::webrtc:", "connection closed");
        this.webrtcConnected = false;
        // this.peer.off();

        // retry connection (if we didn't disconnect manually)
        this.retryWebRtcTimeout = setTimeout(() => {
          if (!this.isDestroyed && this.webrtcRetryCount < 5) {
            this.startWebrtc();
            this.webrtcRetryCount++;
          }
        }, 3000);
      });

      this.peer.on("error", (err) => {
        log(this._idToHuman(), "::webrtc:", "connection error", err);
        this.webrtcConnected = false;
      });
    }
    // if we are host and this state is not for ourselves
    else if (this.isRenderServerOrHostIfNotCasting() && this.myId !== this.id) {
      log("webrtc::connecting", "is render server, notMyId");
      // ping checker (host only)
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = setInterval(() => {
        this.send({
          ping: Date.now(),
        });
      }, 5000);

      // for webrtc, we wait for the signal from this player before responding with a signal
      this.peer = new Peer({ objectMode: true });
      this.peer.on("signal", (data) => {
        log("got signal", data);
        this.send({ for: this.id, signal: data }, true);
      });

      this.peer.on("connect", () => {
        log("connected to player via webrtc");
        this.webrtcConnected = true;
        this.send({ ping: Date.now() });
        this.emit("webrtc_connected");
      });

      this.peer.on("data", (data) => {
        // got a data channel message
        log(this._idToHuman(), "::webrtc:", data);
        data = JSON.parse(data);
        if (data.pinput) {
          // player sent their new inputs, process them
          this.handleInput(data.pinput);
        } else if (data.pstate) {
          this.setState(data.d[0], data.d[1]);
        } else if (data.pong) {
          // response to ping arrived
          this.handlePingResponse(data);
        }
      });

      this.peer.on("close", () => {
        this.webrtcConnected = false;
        // this.peer.off();
        log(this._idToHuman(), "::webrtc:", "connection closed");
      });

      this.peer.on("error", (err) => {
        this.webrtcConnected = false;

        log(this._idToHuman(), "::webrtc:", "connection error", err);
      });
    }
    // // if we are host and this is our local state
    // else if (this.isHost && this.myId === this.id){
    //   // TODO: broadcast local state changes
    // }
    // // if we are not host and this state is not for us.
    // else if (!this.isHost && this.myId !== this.id){
    //   // TODO: read message from state and webrtc player updates and update local state
    // }
  }

  _idToHuman() {
    return `${this.playerIsSpectator ? "spectator" : "player"}(${this.id})`;
  }

  signal(data) {
    if (this.peer.destroyed && this.isRenderServerOrHostIfNotCasting()) {
      // recreate the webrtc client
      this.startWebrtc();
    }

    try {
      log("signaling", data);
      this.peer.signal(data);
    } catch (e) {
      log(e);
    }
  }

  handlePingResponse(pongData) {
    var diff = Date.now() - pongData.pong;
    // log("ping", this.id, diff, "ms");
    this.setState("p", diff, false);
    this.emit("ping", diff);
  }

  // used for local players
  attachControllerLegacy(controller) {
    this.detachControllerLegacy();
    this.controllerLegacy = controller;
    this.controllerLegacy.on("keydown", this.handleKeyDown.bind(this));
    this.controllerLegacy.on("keyup", this.handleKeyUp.bind(this));
    this.controllerLegacy.on("dpad", this.handleDpad.bind(this));
    this.controllerLegacy.on("gyro", this.handleGyro.bind(this));
  }

  detachControllerLegacy() {
    if (this.controllerLegacy) {
      const controller = this.controllerLegacy;
      this.controllerLegacy.off("keydown", this.handleKeyDown);
      this.controllerLegacy.off("keyup", this.handleKeyUp);
      this.controllerLegacy.off("dpad", this.handleDpad);
      this.controllerLegacy.off("gyro", this.handleGyro);
      delete this.controllerLegacy;
      return controller;
    }
  }

  onKeyPress(key, callback) {
    this.on("keypress", (data) => {
      if (data.key === key) callback(data);
    });
  }

  onKeyUp(key, callback) {
    this.on("keyup", (data) => {
      if (data.key === key) callback(data);
    });
  }

  onKeyDown(key, callback) {
    this.on("keydown", (data) => {
      if (data.key === key) callback(data);
    });
  }

  onQuit(callback) {
    return this.on("quit", callback);
  }

  handleKeyDown(key) {
    this.handleInput({ keydown: key });
  }

  handleKeyUp(key) {
    this.handleInput({ keyup: key });
  }

  handleDpad(value) {
    this.handleInput({ dpad: value });
  }

  handleGyro(value) {
    this.handleInput({ gyro: value });
  }

  // handle the input, pass it to subscribers (usually the game logic which will use this to move players in engine)
  handleInput(data, skipBroadcast) {
    let hasChanged = false;
    Object.keys(data).forEach((i) => {
      const key = data[i];
      if (i === "keydown" && !this.inputState[key]) {
        this.inputState[key] = true;
        this.emit("keydown", { key });
        this.emit("keypress", { key });
        hasChanged = true;
      }
      if (i === "keyup" && this.inputState[key]) {
        delete this.inputState[key];
        this.emit("keyup", { key });
        hasChanged = true;
      }
      if (i === "dpad") {
        this.inputState["dpad"] = data.dpad;
        hasChanged = true;
      }
      if (i === "gyro") {
        this.inputState["gyro"] = data.gyro;
        hasChanged = true;
      }
    });
    if (hasChanged) {
      // we just emit the input event
      log("inputEmit", data);
      this.emit("input", data);
      if (!skipBroadcast) {
        this.emit("input_broadcast", data);
      }
    }
  }

  isKeyDown(key) {
    return this.inputState[key];
  }

  on(name, fn, isTemporary) {
    if (name === "profile") {
      fn(this.state["profile"]);
    }
    if (name === "webrtc_connected" && this.webrtcConnected) {
      fn();
    }
    return super.on(name, fn, isTemporary);
  }

  getState(key) {
    if (!key) return this.state;
    // To avoid mutating the state object, we return a copy of it.
    if (typeof this.state[key] === "object") {
      return JSON.parse(JSON.stringify(this.state[key]));
    }
    return this.state[key];
  }

  // public method to change state object (used by host only or to change my own state). This is then synced with all clients.
  setState(key, newState, reliable) {
    // only set / send if the values are different

    // TODO: replace with something better than stringify
    if (JSON.stringify(this.state[key]) === JSON.stringify(newState)) return;
    // log("setState::", this.id, this.state[key], "->", newState)
    this.setLocalState(key, newState);
    // if (JSON.stringify(newState) === JSON.stringify(this.getState(key))) return;
    this.iUpdatedStateAt[key] = Date.now();
    if (this.isRenderServerOrHostIfNotCasting() && !reliable) {
      this.broadcastUnreliable({
        pstate: this.id,
        d: [key, newState],
        o: Date.now() - this.getBootDate(),
      });
    } else {
      this.send(
        {
          pstate: this.id,
          d: [key, newState],
          o: Date.now() - this.getBootDate() - this.timeDiffFromServer,
        },
        reliable
      );
    }
  }

  getProfile() {
    let profile = this.getState("profile") || { color: "#ffffff", name: "" };
    // add rgb color to profile
    if (profile && profile.color) {
      profile = { ...profile, color: hexToRgb(profile.color) };
    }

    if (this.avatarList && this.avatarList.length > 0) {
      profile.avatarIndex = this.avatarList.indexOf(profile.photo);
    }
    if (profile.avatarIndex === undefined) {
      profile.avatarIndex = -1;
    }
    return profile;
  }

  setRoundState(key, newState, reliable) {
    this.setState(`round.${key}`, newState, reliable);
  }

  getRoundState(key) {
    if (key) return this.getState(`round.${key}`);
    else {
      let roundState = {};
      Object.keys(this.getState()).forEach((key) => {
        if (key.startsWith("round.")) {
          roundState[key.substring(6)] = this.getState(key);
        }
      });
      return roundState;
    }
  }

  resetRoundState() {
    Object.keys(this.getState()).forEach((key) => {
      if (key.startsWith("round.")) {
        this.setState(key, undefined);
      }
    });
  }

  setFullLocalState(newState, updateOrder) {
    Object.keys(newState).forEach((key) => {
      if (
        !this.iUpdatedStateAt[key] ||
        this.iUpdatedStateAt[key] + this.syncIntervalTime < Date.now()
      ) {
        this.setLocalState(key, newState[key], updateOrder);
      }
    });

    // also check for any deleted state
    Object.keys(this.state).forEach((key) => {
      if (
        !this.iUpdatedStateAt[key] ||
        this.iUpdatedStateAt[key] + this.syncIntervalTime < Date.now()
      ) {
        if (newState[key] === undefined) {
          this.setLocalState(key, undefined, updateOrder);
        }
      }
    });
    // this.state = newState || {};
  }

  // just change local state without broadcasting
  // updateOrder: since we have two channels the state can come from,
  // we keep order in mind and only update if it's latest from host
  setLocalState(key, newState, updateOrder) {
    // skip if this is older update
    if (
      updateOrder &&
      this.stateKeyUpdateOrder[key] &&
      this.stateKeyUpdateOrder[key] > updateOrder
    ) {
      // log("skipping", key, this.stateKeyUpdateOrder[key] - updateOrder);
      return;
    }
    this.stateKeyUpdateOrder[key] = updateOrder || 0;
    const newStateStr = JSON.stringify(newState);
    var stateUpdated = false;
    if (JSON.stringify(this.state[key]) !== newStateStr) {
      stateUpdated = true;
    }
    const newStateCopied =
      typeof newState === "object" ? JSON.parse(newStateStr) : newState;
    this.state[key] = newStateCopied;

    if (stateUpdated) {
      this.emit("state", key, newStateCopied);
      if (key === "profile") {
        this.emit("profile", newStateCopied);
      }
    }
  }

  disconnect(eventCode) {
    log("[PlayerState] disconnecting with eventCode:", eventCode);
    this.detachControllerLegacy();
    clearInterval(this.heartbeatInterval);
    this.isDestroyed = true;
    if (this.peer) {
      this.peer.destroy();
    }
    this.emit("quit", this);
  }

  isBot() {
    return this.state["__bot"];
  }
}
