import React, { useEffect, useState } from "react";
import Game from "common/components/GameRenderer";
import GameScene from "./src/scene";
import GamesMenu from "common/components/GamesMenu";
import Multiplayer from "common/modules/multiplayer";
import BackgroundGame from "common/components/BackgroundGame";
import ControllerHUD from "common/components/ControllerHUD";
import GyroController from "common/modules/controllers/gyro";
import {
  useGlobalRoundState,
  useStateIsSetForAllPlayers,
} from "common/hooks/multiplayer";

import StartButtonWithGyroPermissions from "common/components/StartButtonWithGyroPermissions";
import { FullscreenCountdown } from "common/components/Countdown";
import { FullscreenAnimatedGradient2 } from "./components/FullscreenAnimatedGradient";
import FloatingShapes from "./components/FloatingShapes";
import { GenericStartScreen } from "common/components/StartScreen";
import imgJarController from "./src/assets/jarcontroller.png";
// import "./style.css";

export default function GamePage({ gameInfo, endRound }) {
  const multiplayer = Multiplayer();
  const myState = multiplayer.getMyPlayerState();

  const gameIsLive = useGlobalRoundState("live");
  const startGame = useGlobalRoundState("startGame");
  const winnerFound = useGlobalRoundState("winner");
  const [countdown, setCountdown] = useState(false);
  const [gameVisible, setGameVisible] = useState(true);

  // const [winnerFound, setWinnerFound] = useState(
  //   multiplayer.getRoundState("winner") || false
  // );

  useEffect(() => {
    if (winnerFound) endRound(winnerFound);
  }, [winnerFound]);

  const [_initSensor, setInitSensor] = useState(false);

  const _allPlayersReady = useStateIsSetForAllPlayers("ready");

  const stopCountdown = () => {
    console.log("Stopping countdown");
    multiplayer.setRoundState("live", true);
    setCountdown(false);
  };

  // // if all players are ready, start the game.
  // useEffect(() => {
  //   if (_allPlayersReady) {
  //     // as soon as all players are ready, we start the game and don't look back.
  //     // if you want to stop the game if someone suddenly becomes "not ready"...
  //     //                                     👇 _allPlayersReady
  //     multiplayer.setRoundState("startGame", true, true);
  //   }
  // }, [_allPlayersReady]);

  useEffect(() => {
    window.reloadGame = () => {
      setGameVisible(false);
      setTimeout(() => {
        setGameVisible(true);
      }, 1000);
    };
  }, []);

  useEffect(() => {
    if (!startGame) return;
    if (multiplayer.isSpectator) {
      setCountdown(true);
    }
  }, [startGame]);

  // if the permission for user has been granted,
  // initialize the controller
  // and mark yourself as "ready".
  useEffect(() => {
    if (!_initSensor) return;

    var controller = null;
    var cleanupFns = [];
    // cleanupFns.push(
    //   multiplayer.on("state", (state, key) => {
    //     setWinnerFound(state["winner"] || false);
    //   })
    // );

    if (!multiplayer.isSpectator) {
      // create the controller
      controller = new GyroController();
      multiplayer.attachController(controller);

      // announce that you've gotten permission to play
      myState.setState("ready", true, true);
    }

    return () => {
      cleanupFns.forEach((cleanup) => cleanup());
      if (!multiplayer.isSpectator) {
        multiplayer.detachControllerLegacy();
        if (controller) controller.destroy();
        controller = null;
      }
    };
    // eslint-disable-next-line
  }, [_initSensor]);

  if (!_allPlayersReady) {
    return (
      <div
        style={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          top: 0,
          left: 0,
          // zIndex: 9999,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "0rem 2rem",

          // pointerEvents: "none"
        }}
      >
        {multiplayer.isSpectator && <FloatingShapes />}
        <BackgroundGame style={{ backgroundColor: "#F6C544" }}></BackgroundGame>
        {/* <FullscreenAnimatedGradient2 containerStyles={{
            opacity: 0.76,
            mixBlendMode: "multiply",
            pointerEvents: "none",
            zIndex: 999,
          }} /> */}

        {!multiplayer.isSpectator ? (
          !_initSensor ? (
            <StartButtonWithGyroPermissions
              style={{
                margin: "1rem 2rem",
              }}
              buttonText={"Tap here to grant permissions!"}
              onPermissionGranted={(isPermissionGranted) => {
                console.log("Permissions granted! Now what?");
                setInitSensor(isPermissionGranted);
              }}
            />
          ) : (
            <>
              You're ready!
              <br />
              Waiting for other players to grant permissions...
            </>
          )
        ) : (
          <>
            <div
              className="centered-container banana-text"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: "1",
                width: "max-content",
                zIndex: 99999,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h1 className="game-title">Round 3</h1>
                <h2>Please accept the dialog on your phone to proceed</h2>
                {/* <img width={"50%"} src={fingerCardsImg} alt={"Cover Image with fingers on cards"} /> */}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      <BackgroundGame
        style={
          multiplayer.isSpectator
            ? { backgroundColor: "#F6C544" }
            : {
                backgroundColor: "#F6C544",
                backgroundImage: `url(${imgJarController})`,
                backgroundSize: "contain",
                backgroundSize: "60vw",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center 30%",
              }
        }
      ></BackgroundGame>
      <GamesMenu
        key="gamesmenu"
        inGame
        showLogo
        gameInfo={gameInfo}
        showRestart={winnerFound && multiplayer.isHost}
      />

      {!multiplayer.isSpectator && (
        <ControllerHUD
          hudStyle={{ backgroundColor: "transparent" }}
          breadcrumbs={["Shake The Jar"]}
          // backgroundStyle={{
          //   background: "transparent"
          //   // `linear-gradient(180.27deg, #4622AD 16.85%, #8D6CEE 120.37%, #6BDCFF 140.73%, #6BFFB8 158.06%)`,
          // // "linear-gradient(180deg, #FFB0CC 49.66%, #76A7B7 104.47%, #6BFFB8 157.42%)",

          // }}
          // avatarStyle={{
          //   background: `linear-gradient(180deg, #8D6BED 49.66%, #76A7B7 104.47%, #6BFFB8 157.42%)`,
          // }}
          // avatarUrl={imgRocket}
        />
      )}

      <GenericStartScreen
        hide={startGame || gameIsLive}
        showButtons={multiplayer.isHost}
        multiplayer={multiplayer}
        textProps={{
          className: "banana-text",
          style: {
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        onStart={() => {
          // multiplayer.setRoundState("playInstructions", false);
          multiplayer.setRoundState("startGame", true);
        }}
      ></GenericStartScreen>

      {multiplayer.isSpectator ? (
        <>
          {countdown ? (
            <FullscreenCountdown
              key={gameIsLive || "fullscreen-countdown"}
              startingFrom={4}
              textOverrides={["Ready"]}
              onCountdownChange={setCountdown}
              onFinish={stopCountdown}
              countdownContainerStyles={{
                fontFamily: "Lilita One",
                fontWeight: 400,
                textTransform: "none",
              }}
            />
          ) : (
            <></>
          )}
          {gameVisible && (
            <Game
              key="game"
              fullScreen
              gameIsLive={gameIsLive}
              gameInfo={gameInfo}
              scenes={[GameScene]}
              physics={{
                default: "matter",
                matter: {
                  gravity: {
                    y: 1,
                  },
                  // debug: true,
                },
              }}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
