import React, { useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import "./App.css";

import Home from "./pages/Home";
import GameLobby from "./pages/games/Lobby";
import Profile from "./pages/profile";
import IframeScreen from "./pages/iframe";
import WaitScreenWait from "./pages/waitScreen/wait";
import WaitScreen from "./pages/waitScreen";
import EndScreen from "./pages/endScreen";
import AutoplayScreen from "./pages/autoplayScreen";
import Modal from "common/components/Modal";
import NativeMethods from "common/modules/NativeMethods";
import Multiplayer from "common/modules/multiplayer";
import { createMemoryHistory } from "history";
import GamesDirectory from "pages/games/directory";
import { getMyId } from "common/modules/auth";
import { addUserLog } from "common/modules/db";
import Analytics from "common/analytics";
const history = createMemoryHistory();

function App() {
  console.log("App Loaded");
  const myId = getMyId();
  Analytics.identify(myId);
  // Auth();
  addUserLog("apploaded");
  window.memhistory = history;
  const multiplayer = Multiplayer(history);
  window.multiplayer = multiplayer;
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  React.useEffect(() => {
    var unlisten;
    var profileComplete = false;
    NativeMethods.setStandbyURL(
      `${window.location.protocol}//${window.location.host}/cast.html`
    );
    // if host, sync router with other players.
    multiplayer.on("connected", () => {
      addUserLog("connected");
      if (multiplayer.isHost) {
        profileComplete = true;
        if (window.parent && window.parent.onHostCreate) {
          window.parent.onHostCreate(multiplayer.currentRoom);
        }
      }
      setIsReconnecting(false);
      console.log(
        `${window.location.protocol}//${window.location.host}/#spectate=${multiplayer.currentRoom}`
      );
      NativeMethods.loadURL(
        `${window.location.protocol}//${window.location.host}/#spectate=${multiplayer.currentRoom}`
      );
      unlisten = history.listen((location, action) => {
        if (multiplayer.isRenderServer()) {
          multiplayer.navigate(location.pathname, location.state);
        }

        console.log(
          `The current URL is ${location.pathname}${location.search}${location.hash}`
        );
        console.log(`The last navigation action was ${action}`);
      });
    });
    multiplayer.on("state", (state, key) => {
      if (multiplayer.getState("waitingPlayers")) {
        const list = multiplayer.getState("waitingPlayers");
        if (list && list.length > 0 && list.includes(myId)) {
          setIsWaiting(true);
        } else {
          setIsWaiting(false);
        }
      } else {
        setIsWaiting(false);
      }
      if (key === "path") {
        // auto navigate profile page if incomplete
        if (!profileComplete && !multiplayer.isSpectator) {
          // unless the profile is not complete. do that first
          history.push("/profile");
          profileComplete = true;
        }
        // don't auto-navigate render server to profile page
        // else if (multiplayer.isRenderServer() && state['path'].pathname !== "/profile") {
        //   history.push(state['path'].pathname);
        // }
        // don't navigate away from profile page
        else if (
          history.location.pathname !== "/profile" &&
          state["path"].pathname !== history.location.pathname
        ) {
          console.log(
            "NETWORK NAVIGATE",
            state["path"],
            multiplayer.isRenderServer()
              ? "render"
              : multiplayer.isHost
              ? "host"
              : "player"
          );
          history.push(
            state["path"].pathname,
            state["path"].state ? JSON.parse(state["path"].state) : null
          );
        }
      }
    });

    multiplayer.on("reconnecting", () => {
      setIsReconnecting(true);
    });

    multiplayer.on("disconnected", ({ eventCode }) => {
      // history.push("/");
      // window.location.hash = "";

      console.log("eventCode:", eventCode);

      if (window.location.hash.includes("disconnected")) {
        window.location.href = "/#trigger=true";
      }

      // This is where we redirect upon disconnect
      window.location.href = "/#disconnected=" + eventCode;
      // + "true";
      setIsReconnecting(false);
      profileComplete = false;
      // window.RotateScreen("portrait");
    });
    return unlisten;
  });

  return (
    <div className="App">
      <Modal open={isWaiting}>
        <h3>Waiting for current game to end.</h3>
      </Modal>
      <Modal open={isReconnecting}>
        <h3>Reconnecting...</h3>
      </Modal>
      <div className="touch-twobutton-zone">
        <div className="button1">
          <div className="circle"></div>
        </div>
        <div className="button2"></div>
      </div>
      <div className="touch-joystick-zone">
        <div className="dpad">
          <div className="circle"></div>
        </div>
        <div className="button1"></div>
        <div className="button2"></div>
      </div>
      <div className="gyro-joystick-zone">
        {/* <div className="button-black btn-start">Tap to play</div> */}
        <div className="debug"></div>
      </div>
      <div className="font-load-hack">
        <span>;</span>
      </div>
      <Router history={history}>
        <Route
          render={({ location }) => (
            <AnimatePresence initial={false}>
              <Switch location={location} key={location.pathname}>
                <Route
                  exact
                  path="/games/lobby"
                  render={() => (
                    <MotionWrap>
                      <GameLobby />
                    </MotionWrap>
                  )}
                />
                <Route
                  exact
                  path="/"
                  render={() => (
                    <MotionWrap>
                      <Home />
                    </MotionWrap>
                  )}
                />
                <Route
                  exact
                  path="/profile"
                  render={() => (
                    <MotionWrap>
                      <Profile />
                    </MotionWrap>
                  )}
                />
                <Route
                  exact
                  path="/iframe"
                  render={() => (
                    <MotionWrap>
                      <IframeScreen />
                    </MotionWrap>
                  )}
                />
                <Route
                  exact
                  path="/waitscreen"
                  render={() => (
                    <MotionWrap>
                      <WaitScreen />
                    </MotionWrap>
                  )}
                />
                <Route
                  exact
                  path="/endscreen"
                  render={() => (
                    <MotionWrap>
                      <EndScreen />
                    </MotionWrap>
                  )}
                />
                <Route
                  exact
                  path="/waitscreen/wait"
                  render={() => (
                    <MotionWrap>
                      <WaitScreenWait />
                    </MotionWrap>
                  )}
                />
                <Route
                  exact
                  path="/autoplaygame"
                  render={() => (
                    <MotionWrap>
                      <AutoplayScreen />
                    </MotionWrap>
                  )}
                />
                {GamesDirectory.map((gameInfo) => {
                  const pages = gameInfo.pages;
                  return pages.map((page) => {
                    const Component = page.component;
                    return (
                      <Route
                        exact
                        path={`/games/${page.slug}`}
                        render={() => (
                          <MotionWrap>
                            <Component gameInfo={gameInfo} />
                          </MotionWrap>
                        )}
                      />
                    );
                  });
                })}
              </Switch>
            </AnimatePresence>
          )}
        />
      </Router>
    </div>
  );
}

const pageVariants = {
  initial: {
    opacity: 0,
    // x: "-100vw",
    // scale: 0.8
  },
  in: {
    opacity: 1,
    // x: 0,
    // scale: 1
  },
  out: {
    opacity: 0,
    // x: "100vw",
    // scale: 1.2
  },
};

const pageTransition = {
  type: "tween",
  ease: "easeOut",
  duration: 0.3,
};

function MotionWrap(props) {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      {props.children}
    </motion.div>
  );
}

export default App;
