import React from "react";
import "./style.css";

export default function Toast({
  middle,
  center,
  bottom,
  right,
  visible,
  children,
  flag,
  style,
  containerClassName,
}) {
  if (!visible) return null;
  return (
    <div
      style={style}
      className={[
        "toast-container",
        bottom ? "bottom" : "",
        right ? "right" : "",
        flag ? " with-flag" : "",
        containerClassName || "",
      ].join(" ")}
    >
      {children}
    </div>
  );
}
