import Multiplayer from "./index";
import Timer from "../../components/Timer";

export function stateIsSetForAllPlayers(stateKey) {
  const multiplayer = Multiplayer();
  const playerStates = Object.entries(multiplayer.getPlayers()).map(
    ([pid, player]) => player
  );
  let allPlayersHaveState = playerStates.every(
    (player) =>
      player.getState(stateKey) !== undefined &&
      player.getState(stateKey) !== null
  );
  return allPlayersHaveState;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function waitUntilStateIsSetForPlayer(player, stateKey) {
  return new Promise((resolve, reject) => {
    if (player.getState(stateKey) !== undefined) {
      resolve();
    } else {
      const cleanupFn = player.on(
        "state",
        (key, newState) => {
          if (key === stateKey) {
            cleanupFn();
            resolve();
          }
        },
        true
      );
    }
  });
}

export function waitUntilStateIsSetForAllPlayers(stateKey) {
  const multiplayer = Multiplayer();
  return new Promise((resolve, reject) => {
    const playerStates = Object.entries(multiplayer.getPlayers()).map(
      ([pid, player]) => player
    );
    let allPlayersHaveState = playerStates.every(
      (player) =>
        player.getState(stateKey) !== undefined &&
        player.getState(stateKey) !== null
    );
    if (allPlayersHaveState) {
      resolve();
    } else {
      const cleanupFns = playerStates.map((player) =>
        player.on(
          "state",
          (key, newState) => {
            if (key === stateKey) {
              const allPlayersHaveState = playerStates.every(
                (player) =>
                  player.getState(stateKey) !== undefined &&
                  player.getState(stateKey) !== null
              );
              if (allPlayersHaveState) {
                cleanupFns.forEach((fn) => fn());
                resolve();
              }
            }
          },
          true
        )
      );
    }
  });
}

export function waitUntilGlobalStateIsSet(stateKey, callbackOnSet) {
  const multiplayer = Multiplayer();
  return new Promise((resolve, reject) => {
    const resolveWrapper = (stateData) => {
      if (callbackOnSet) {
        callbackOnSet(stateData);
      }
      resolve(stateData);
    };
    let state = multiplayer.getState(stateKey);
    if (state) resolveWrapper(state);
    const cleanupFn = multiplayer.on("state", (state, key) => {
      if (key === stateKey) {
        cleanupFn();
        resolveWrapper(state[key]);
      }
    });
  });
}

export function waitUntilPlayerStateIsSet(player, stateKey, callbackOnSet) {
  return new Promise((resolve) => {
    const resolveWrapper = (stateData) => {
      if (callbackOnSet) {
        callbackOnSet(stateData);
      }
      resolve(stateData);
    };
    let state = player.getState(stateKey);
    if (state) resolveWrapper(state);
    const cleanupFn = player.on("state", (key, state) => {
      if (key === stateKey) {
        cleanupFn();
        resolveWrapper(state);
      }
    });
  });
}

export function startTimer(timeInSeconds, forCurrentRound = false) {
  const multiplayer = Multiplayer();
  const randId = Math.random().toString(36).substring(7);
  if (forCurrentRound) {
    multiplayer.setRoundState("timer_id", randId);
  } else {
    multiplayer.setState("timer_id", randId);
  }
  var cleanupFn = null;
  return new Promise((resolve, reject) => {
    const _intervalId = Timer({
      isNonDisplay: true,
      setTimer: (val) => {
        if (forCurrentRound) multiplayer.setRoundState("timer", val);
        else multiplayer.setState("timer", val);
      },
      durationInMs: timeInSeconds * 1000,
      onAllFinish: (x, intervalId) => {
        if (cleanupFn) cleanupFn();
        window.clearInterval(intervalId);
        if (forCurrentRound) multiplayer.setRoundState("timer", 0);
        else multiplayer.setState("timer", 0);
        resolve();
      },
    });

    cleanupFn = multiplayer.on("state", (fullState, key) => {
      const timerIdKey = forCurrentRound ? "round.timer_id" : "timer_id";
      if (key === timerIdKey && fullState[timerIdKey] !== randId) {
        window.clearInterval(_intervalId);
        if (cleanupFn) cleanupFn();
        reject();
      }
    });
  });
}
