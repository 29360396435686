import { useState, useEffect } from "react";
import Multiplayer from "../modules/multiplayer";

// import { useForceUpdate } from "./useForceUpdate";

function ObjToArr(obj) {
  return Object.keys(obj).map((pid) => obj[pid]);
}

export function usePlayersList(triggerOnPlayerState) {
  const multiplayer = Multiplayer();
  const [players, setPlayers] = useState(ObjToArr(multiplayer.getPlayers()));

  usePlayerState(() => {
    if (triggerOnPlayerState) {
      setPlayers(ObjToArr(multiplayer.getPlayers()));
    }
  });

  useEffect(() => {
    const cleanupFn = multiplayer.on(
      "players",
      (playersList) => {
        setPlayers(ObjToArr(playersList));
      },
      true
    );

    return cleanupFn;
    // eslint-disable-next-line
  }, []);

  return players;
}

// stateKey here is optional, if not provided, this will update on any state changes
export function useGlobalState(stateKey, defaultValue) {
  const multiplayer = Multiplayer();
  if (
    defaultValue !== undefined &&
    multiplayer.getState(stateKey) === undefined
  ) {
    multiplayer.setState(stateKey, defaultValue);
  }
  const [state, setState] = useState(
    !stateKey || multiplayer.getState(stateKey) !== undefined
      ? multiplayer.getState(stateKey)
      : defaultValue
  );

  useEffect(() => {
    const cleanupFn = multiplayer.on("state", (state, key) => {
      if (stateKey && key === stateKey) {
        if (state !== state[key]) {
          setState(state[key]);
        }
      } else if (!stateKey) {
        setState(state);
      }
    });

    return cleanupFn;
    // eslint-disable-next-line
  }, []);
  return state;
}

export function useGlobalRoundState(stateKey, defaultValue) {
  return useGlobalState(`round.${stateKey}`, defaultValue);
}

// callback is called on any player's state change. callback gets as argument:
// - the playerId as 1st argument,
// - the key of state that changed as 2nd argument,
// - the whole player state as 3rd argument
// - player's multiplayer object as 4th argument
export function usePlayerState(callback) {
  const multiplayer = Multiplayer();

  useEffect(() => {
    var cleanupFns = [];
    cleanupFns.push(
      multiplayer.on(
        "joined",
        (player) => {
          cleanupFns.push(
            player.on(
              "state",
              (key, state) => {
                callback(player.id, key, player.state, player);
              },
              true
            )
          );
        },
        true
      )
    );
    return () => {
      cleanupFns.forEach((cleanup) => cleanup());
    };
    // eslint-disable-next-line
  }, []);
}

// export function useMyPlayerState(stateKey, defaultValue) {
//   const multiplayer = Multiplayer();
//   const myState = multiplayer.getMyPlayerState();

//   const [state, setState] = useState(
//     !stateKey || myState.getState(stateKey) !== undefined
//       ? myState.getState(stateKey)
//       : defaultValue
//   );

//   useEffect(() => {
//     const cleanupFn = myState.on("state", (key, newState) => {
//       if (stateKey && key === stateKey) {
//         if (state !== newState) {
//           setState(newState);
//         }
//       } else if (!stateKey) {
//         setState(myState.getState());
//       }
//     });

//     return cleanupFn;
//     // eslint-disable-next-line
//   }, []);
//   return state;
// }

// callback is called on any player's state change. callback gets as argument:
// - the playerId as 1st argument,
// - the whole player input object as 2rd argument
// - player's multiplayer object as 3th argument
export function usePlayerInput(callback) {
  const multiplayer = Multiplayer();

  useEffect(() => {
    var cleanupFns = [];
    cleanupFns.push(
      multiplayer.on(
        "joined",
        (player) => {
          cleanupFns.push(
            player.on(
              "input",
              (data) => {
                callback(player.id, player.inputState, player);
              },
              true
            )
          );
        },
        true
      )
    );
    return () => {
      cleanupFns.forEach((cleanup) => cleanup());
    };
    // eslint-disable-next-line
  }, []);
}

// get a state of current player, stateKey is key of the state to watch
export function useMyPlayerState(stateKey, defaultValue) {
  const multiplayer = Multiplayer();
  const myState = multiplayer.getMyPlayerState();
  if (defaultValue !== undefined && myState.getState(stateKey) === undefined) {
    myState.setState(stateKey, defaultValue);
  }
  const [state, setState] = useState(
    myState.getState(stateKey) || defaultValue || undefined
  );

  useEffect(() => {
    if (multiplayer.isSpectator) return;
    const cleanupFn = myState.on("state", (key, state) => {
      if (key === stateKey) {
        setState(myState.getState(stateKey));
        console.log("STATE OF PLAYER", key, state);
      }
    });

    return cleanupFn;
    // eslint-disable-next-line
  }, []);
  return state;
}

// get a state of some player, stateKey is key of the state to watch
export function useOtherPlayerState(playerState, stateKey, defaultValue) {
  if (
    defaultValue !== undefined &&
    playerState.getState(stateKey) === undefined
  ) {
    playerState.setState(stateKey, defaultValue);
  }
  const [state, setState] = useState(
    playerState.getState(stateKey) || defaultValue || undefined
  );

  useEffect(() => {
    const cleanupFn = playerState.on("state", (key, state) => {
      if (key === stateKey) {
        setState(playerState.getState(stateKey));
        console.log("STATE OF PLAYER", key, state);
      }
    });

    return cleanupFn;
    // eslint-disable-next-line
  }, []);
  return state;
}

// get a state of all players, stateKey is key of the state to watch
// Returns an array in this shape: [{id: player1Id, state: stateValue}, {id: player2Id, state: stateValue}, ...]
export function useAllPlayersState(playerStates, stateKey, defaultValue) {
  // const forceUpdate = useForceUpdate();
  // We don't use actual state var here. We return calculated values.
  const [_state, setState] = useState(Math.random());

  // useEffect(() => {}, [state]);

  // if (!window.multiplayer.isSpectator) console.log(window.multiplayer.getMyPlayerState().id, "useStateIsSetForAllPlayers > useAllPlayersState rerender", playerStates?.map((playerState) => {
  //   return {
  //     playerId: playerState.id,
  //     state: playerState.getState(stateKey),
  //   };
  // }), state);

  useEffect(() => {
    const cleanupFns = playerStates.map((playerState) =>
      playerState.on(
        "state",
        (key, updatedState) => {
          // if (!window.multiplayer.isSpectator) console.log(window.multiplayer.getMyPlayerState().id, "useStateIsSetForAllPlayers > useAllPlayersState allupdatesOnState", key, updatedState, playerState.id)
          if (key === stateKey) {
            // if (!window.multiplayer.isSpectator) console.log(window.multiplayer.getMyPlayerState().id, "useStateIsSetForAllPlayers > useAllPlayersState init", updatedState, playerState.id);
            setState(Math.random());
          }
        },
        true
      )
    );

    // if (!window.multiplayer.isSpectator) console.log("useStateIsSetForAllPlayers > useAllPlayersState", stateKey, state)

    return () => {
      cleanupFns.forEach((cleanup) => cleanup());
    };
  }, [playerStates, stateKey]);

  // console.log("useStateIsSetForAllPlayers > useAllPlayersState", stateKey, state)
  return (
    playerStates?.map((playerState) => {
      return {
        playerId: playerState.id,
        state: playerState.getState(stateKey),
      };
    }) ||
    defaultValue ||
    []
  );
}

export function usePlayersState(stateKey) {
  const players = usePlayersList();
  const allPlayerStates = useAllPlayersState(players, stateKey, undefined);

  return allPlayerStates.map((playerState) => ({
    player: players.find((p) => p.id === playerState.playerId),
    state: playerState.state,
  }));
}

// returns true if all player's 'stateKey' is set to a value
// example use: waiting for all players to be ready
export function useStateIsSetForAllPlayers(stateKey) {
  const players = usePlayersList();
  const allPlayerStates = useAllPlayersState(players, stateKey, undefined);
  const [state, setState] = useState(
    allPlayerStates.every(
      (playerState) =>
        playerState.state !== undefined && playerState.state !== null
    )
  );

  // if (!window.multiplayer.isSpectator) console.log(window.multiplayer.getMyPlayerState().id, "useStateIsSetForAllPlayers",stateKey, allPlayerStates, state)

  useEffect(() => {
    if (allPlayerStates && Array.isArray(allPlayerStates)) {
      const allStatesAreSet = allPlayerStates.every(
        (playerState) =>
          playerState.state !== undefined && playerState.state !== null
      );
      setState(allStatesAreSet);
    } else {
      setState(false);
    }
  }, [allPlayerStates]);
  return state;
}

export function useIsHost() {
  const multiplayer = Multiplayer();
  const [state, setState] = useState(multiplayer.isHost);

  useEffect(() => {
    // Safari fix: host_updated event is not fired on Safari if we are host from the start
    setTimeout(() => {
      setState(multiplayer.isHost);
    }, 50);
    const cleanupFn = multiplayer.on("host_updated", () => {
      setState(multiplayer.isHost);
    });

    return cleanupFn;
    // eslint-disable-next-line
  }, [multiplayer.isHost]);
  return state;
}
