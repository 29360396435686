/*
Research:
- Prevent jitters in svg animation: https://css-tricks.com/animating-svg-css/
*/

const CircularProgress = ({
  height,
  width,
  color,
  timer,
  circularProgressStyles = {},
  transitionDelay = 1,
  durationInMs,
}) => {
  if (!timer) return <></>;

  const rotation = (timer || 100) * 31.4 / 100

  return (
    <div
      className={`circular-progress timer-${timer} inverse-timer-${(durationInMs / 1000) - timer}`}
      style={{
        ...{
          transition: `all ${transitionDelay}s`,
        },
        ...circularProgressStyles,
      }}
    >
      <svg
        height={height}
        width={width}
        viewBox="0 0 20 20"
        style={{
          transition: `all ${transitionDelay}s`,
        }}
      >
        <circle
          r="5"
          cx="10"
          cy="10"
          fill="transparent"
          stroke={color}
          strokeWidth="10"
          strokeDasharray={`${rotation} 31.4`}
          transform="rotate(-90) translate(-20)"
          style={{
            transition: `all ${transitionDelay}s`,
          }}
        />
      </svg>
    </div>
  );
};

export default CircularProgress;
