import { useState } from "react";
import { useGlobalState } from "../../hooks/multiplayer";
import Multiplayer from "../../modules/multiplayer";
import styles from "./style.module.css";

export default function MuteButton({ castMode, children, round = true, containerStyles={}, muteIconStyles={}, initialFakeMute:_initialFakeMute=false, onInitialFakeMuteClick=()=>{} }) {
  const multiplayer = Multiplayer();

  const [initialFakeMute, setInitialFakeMute] = useState(_initialFakeMute);

  return (
    <div
      className={`${castMode ? "" : styles.soundContainer}`}
      style={containerStyles}
      onClick={() => {
        if (initialFakeMute) {
          setInitialFakeMute(false);
          onInitialFakeMuteClick();
          return;
        }
        multiplayer.setState(
          "muted",
          multiplayer.getState("muted") ? undefined : true
        );
      }}
    >
      <Mute
        forceMute={initialFakeMute}
        muteButtonClassNames={`${children ? "" : styles.muteButtonContainer}  ${
          round ? styles.round : ""
        }  ${castMode ? styles.castMode : ""}`}
        style={muteIconStyles}
      />
      {children}
    </div>
  );
}

export const Mute = ({ muteButtonClassNames, forceMute=false, ...rest }) => {
  const muted = useGlobalState("muted");

  return (
    <div
      className={`${styles.muteButton} ${muteButtonClassNames} ${
        !forceMute && !muted ? styles.on : ""
      }
        }`}
      {...rest}
    ></div>
  );
};