import DigitalClock from "../DigitalClock";
import CircularProgress from "../CircularProgress";

const setIntervalX = (callback, delay, repetitions, onAllFinish, steps = 1) => {
  var x = 0;
  var _isArray = callback instanceof Array;

  // if window already has a timer, clear it before proceeding
  if (window.timerIntervalId) {
    window.clearInterval(window.timerIntervalId);
    clearInterval(window.timerIntervalId);
  }

  const intervalId = window.setInterval(() => {
    if (x === repetitions) {
      if (onAllFinish) onAllFinish(x, intervalId);
      window.clearInterval(intervalId);
      return;
    }

    if (_isArray) callback[x](x, intervalId);
    else callback(x, intervalId);

    x += steps;
  }, delay);
  window.timerIntervalId = intervalId;
  return intervalId;
};

const Timer = ({ isNonDisplay, setTimer, ...otherProps }) => {
  const { durationInMs, onAllFinish, type } = otherProps;
  if (!isNonDisplay) {
    switch(type) {
      case "digital":
        return <DigitalClock {...otherProps} />;
      default:
        return <CircularProgress {...otherProps} />;
    }
  } else {
    switch(type) {
      case "digital":
        return setIntervalX(
          (x, intervalId) => {
            setTimer(x);
          },
          1000,
          durationInMs / 1000,
          (x, intervalId) => {
            if (onAllFinish) onAllFinish(x, intervalId);
            clearInterval(intervalId);
          }
        );
      default:
        return setIntervalX(
          (x, intervalId) => {
            setTimer(parseInt((x / 360) * 100));
          },
          durationInMs / 360,
          360,
          (x, intervalId) => {
            if (onAllFinish) onAllFinish(x, intervalId);
            clearInterval(intervalId);
          }
        );
    }
  }
};

export default Timer;
